.Profil {
  background-image: var(--bg-profil) !important;
  background-repeat: no-repeat !important;
  background-size: cover;
  background-position: bottom;
}

.Profil_container {
  background-image: url("../../assets/img/bg-profil-bottom.png") !important;
  background-repeat: no-repeat !important;
  background-size: 40% !important;
  background-position: right bottom;
  padding-bottom: 150px;
}
