.Destek__header {
  display: flex;
  width: 100%;
  height: 60px;
  padding: 0 15px;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
}
.Destek__header__title {
  color: var(--Dark-Mode-Text-1, #fff);

  /* Heading/Heading 3 */
  font-family: Mazzard;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.Destek__header__button button {
  display: flex;
  width: 196px;
  height: 56px;
  padding: 8px 32px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  flex-shrink: 0;
  border-radius: 52px;
  background: linear-gradient(180deg, #3ab22f 0%, #237f1a 100%);
}
.Destek__header__line {
  margin: 24px 0;
  border: 1px solid var(--dark-mode-element-dvider-100, rgba(255, 255, 255, 0));
  stroke-width: 1px;
  stroke: var(--Dark-Mode-Element-Divider, rgba(255, 255, 255, 0.1));
  width: 100%;
  border-image: linear-gradient(
    90deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0.4) 50%,
    rgba(255, 255, 255, 0) 100%
  );
  border-image-slice: 1;
}
.Destek__body {
}
.Destek__body_top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
}
.Destek__body_top_left {
  display: flex;
  padding: 4px;
  justify-content: center;
  align-items: center;
  border-radius: 99px;
  background: var(--Dark-Mode-Card-Dark, #1f1f21);
}
.Destek__body_top_left button {
  display: flex !important;
  width: 146px !important;
  height: auto !important;
  padding: 8px 16px !important;
  flex-direction: column !important;
  align-items: center !important;
  border-radius: 48px !important;
  border: none !important;
}

.Destek__body_top_left button {
  background: #0000 !important;
}
.Destek__body_top_left button.btn-primary {
  background: var(--Dark-Mode-Card-Light, #35353a) !important;
}

.Destek__body_top_left button:hover {
  background: #00000020 !important;
}
.Destek__body_top_right {
}
.Destek__Search {
  display: flex;
}
.Destek__Search__input {
  display: flex;
  width: 292px;
  padding: 8px 24px 8px 16px;
  align-items: center;
  gap: 16px;
  border-radius: 16px;
  background: var(--Dark-Mode-Card-Light, #35353a);
  color: var(--Dark-Mode-Input-Placeholder, #9a9a9a);
  text-align: left;

  /* Body/Normal/Regular */
  font-family: Mazzard;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.Destek__body_bottom_none {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  margin-top: 124px;
  gap: 18px;
}
.Destek__body_bottom_none p {
  color: var(--Dark-Mode-Text-1, #fff);

  /* Body/XLarge/Regular */
  font-family: Mazzard;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.Destek__body_bottom_lists {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  margin-top: 24px;
}
.DestekPanel {
  display: flex;
  width: 100%;
  padding: 24px;
  justify-content: flex-start;
  align-items: center;
  border-radius: 24px;
  background: var(--Dark-Mode-Card-Medium, #2b2b2e);
}
.DestekPanel__title {
  display: flex;
  padding: 4px;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
}
.DestekPanel__title__name {
  color: var(--Dark-Mode-Text-3, #797979);
  text-align: center;

  /* Body/Normal/Regular */
  font-family: Mazzard;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.DestekPanel__title__text {
  color: var(--Dark-Mode-Text-1, #fff);
  text-align: center;

  /* Body/XLarge/Semibold */
  font-family: Mazzard;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.w100 {
  width: 100px;
  max-width: 100px;
}
.w200 {
  width: 200px;
  max-width: 200px;
}
.w500 {
  width: 500px;
  max-width: 500px;
}
.cevaplandi {
  color: #d23c3c;
}
.cozuldu {
  color: #797979;
}
.devam {
  color: #3cd253;
}
.textarea {
  border-radius: 10px !important;
  background: var(--Dark-Mode-Card-Light, #2b2b2e) !important;
  color: var(--Dark-Mode-Input-Placeholder, #9a9a9a) !important;
  border: none !important;
}
.DestekPanel__title__text__btn {
  display: flex;
  padding: 8px 16px !important;
  align-items: center !important;
  height: auto !important;
  gap: 8px !important;
  border-radius: 8px !important;
  background: var(--Dark-Mode-Card-Lightest, #444449) !important;
  border: none !important;
  color: var(--Neutral-White, #fff) !important;
  text-align: center !important;

  /* Body/XLarge/Regular */
  font-family: Mazzard !important;
  font-size: 18px !important;
  font-style: normal;
  font-weight: 400 !important;
  line-height: normal;
}
.DestekPanel__title__text__btn:hover {
  border-radius: 8px !important;
  background: var(--Dark-Mode-Card-Lightest, #626268) !important;
}
.DestekDetay__chat {
  display: flex !important;
  width: 100%;
  padding: 56px 0px 24px 0px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 111px;
  margin: 0 auto;
  border-radius: 32px;
  background: var(--Dark-Mode-Card-Dark, #1f1f21);
  box-shadow: 0px 15px 33px 0px rgba(0, 0, 0, 0.13),
    0px 60px 60px 0px rgba(0, 0, 0, 0.11),
    0px 135px 81px 0px rgba(0, 0, 0, 0.07),
    0px 240px 96px 0px rgba(0, 0, 0, 0.02), 0px 374px 105px 0px rgba(0, 0, 0, 0);
}
.DestekDetay__chat-history {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 40px;
  align-self: stretch;
}
.DestekDetay__chat-you,
.DestekDetay__chat-admin {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  gap: 16px;
  align-self: stretch;
}
.DestekDetay__chat-ikon {
  display: flex;
  width: 81px;
  min-width: 81px;
  padding: 12px;
  align-items: self-start;
}
.DestekDetay__chat-ikon img {
  display: flex;
}
.DestekDetay__chat-message {
  display: flex;
  padding: 24px;
  border-radius: 24px;
  background: var(--Dark-Mode-Card-Light, #35353a);
}
.destek-page-header {
  display: flex;
  width: 100%;
  padding: 8px 0px;
  align-items: center;
  gap: 16px;
  margin-top: 32px !important;
}
.destek-page-return {
  color: var(--Dark-Mode-Text-1, #fff);

  /* Heading/Heading 5 */
  font-family: Mazzard;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.DesteDetay__bilgiForm {
  display: flex;
  padding: 10px 24px;
  justify-content: flex-end;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  align-self: stretch;
  border-radius: 24px;
}
.DesteDetay__bilgiForm textarea {
  background: var(--Dark-Mode-Card-Light, #35353a) !important;
}
.y100 {
  width: 100%;
}
.DestekDetay__chat-button {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.DestekDetay__chat-button button {
  height: auto !important;
  width: auto !important;
}
