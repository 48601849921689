.DashBoard_container {
  padding: 32px;
}
.AramaPaneli {
  display: flex;
  width: 100%;
  padding: 16px;
  align-items: flex-start;
  gap: 17px;
  align-items: center;
  border-radius: 24px;
  background: var(--Dark-Mode-Card-Medium, #2b2b2e);
}
.sonucGetirBtn {
  width: 50px !important;
  height: 100% !important;
  padding: 10px !important;
}
.AramaSelect {
  display: inline-flex;
  padding: 16px;
  align-items: center;
  gap: 8px;
  border-radius: 16px;
  background: var(--Dark-Mode-Card-Light, #35353a);
  opacity: 0.4;
}
.AramaSelect.active {
  opacity: 1;
}
.AramaSelect__icon {
}
.AramaSelect__body {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 4px;
}
.AramaSelect__title {
  color: var(--Dark-Mode-Text-1, #fff);
  text-align: center;

  /* Body/XLarge/Semibold */
  font-family: Mazzard;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.sonucGetirBtn2 {
  opacity: 1 !important;
  background-color: #6c757d !important;
  height: 71px !important;
}
.AramaSelect__text {
  color: var(--Dark-Mode-Text-3, #797979);
  text-align: center;

  /* Body/Normal/Regular */
  font-family: Mazzard;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.AramaToplu {
  display: flex;
  align-items: center;
  gap: 8px;
  margin-left: auto;
  cursor: pointer;
}
.AramaToplu svg {
  fill: #797979;
}
.AramaToplu__text {
  color: var(--Dark-Mode-Text-3, #797979);
  text-align: center;

  /* Body/XLarge/Semibold */
  font-family: Mazzard;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.AramaToplu:hover svg,
.AramaToplu:hover .AramaToplu__text {
  fill: #fff;
  color: var(--Dark-Mode-Text-1, #fff);
}
.AnaKumanda__container {
  padding: 0 32px;
  display: flex;
  flex-direction: column;
  gap: 24px;
}
.AnaKumanda__sonuc {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 132px;
}
.AnaKumanda__sonucyok {
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  gap: 40px;
}
.AnaKumanda__sonucyok svg {
  margin-left: 50px;
}
.AnaKumanda__sonucyok__text {
  color: var(--Dark-Mode-Text-1, #fff);
  text-align: center;

  /* Body/XLarge/Regular */
  font-family: Mazzard;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.AnaKumanda__sonucyok__text span {
  color: var(--Dark-Mode-Text-1, #fff);

  /* Body/XXLarge/Semibold */
  font-family: Mazzard;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.Sonuclar_container {
  width: 100%;
  height: 40px;
  justify-content: space-between;
  align-items: center;
  display: inline-flex;
}
.Sonuclar_left {
  justify-content: flex-start;
  align-items: center;
  gap: 24px;
  display: flex;
}
.Sonuclar_left__title {
  color: var(--Dark-Mode-Text-1, #fff);
  font-size: 18px;
  font-family: Mazzard;
  font-weight: 600;
  word-wrap: break-word;
}
.Sonuclar_left__text {
  color: var(--Dark-Mode-Text-1, #fff);
  font-size: 20px;
  font-family: Mazzard;
  font-weight: 700;
  word-wrap: break-word;
}
.Sonuclar_right {
  justify-content: flex-end;
  align-items: center;
  gap: 16px;
  display: flex;
}
.Sonuclar_right__container {
  display: inline-flex;
  align-items: baseline;
  gap: 18px;
}
.Sonuclar_right__sort {
  color: var(--Dark-Mode-Text-1, #fff);
  font-size: 18px;
  font-family: Mazzard;
  font-weight: 400;
  word-wrap: break-word;
}
.Sonuclar_right__sort.active {
  font-weight: 600;
  padding-left: 16px;
  padding-right: 16px;
  padding-top: 8px;
  padding-bottom: 8px;
  border-radius: 16px;
  display: flex;
  border: 1px rgba(255, 255, 255, 0.1) solid;
  justify-content: flex-start;
  align-items: center;
  gap: 16px;
}
.Sonuclar_right__sort__icon {
  position: relative;
}
.Sonuclar__body {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
}
.Sonuclar__body__container {
  display: flex;
  width: 100%;
  padding: 16px 24px;
  justify-content: space-between;
  align-items: center;
  border-radius: 24px;
  border: 1px solid var(--Dark-Mode-Element-Divider, rgba(255, 255, 255, 0));
  background: var(--Dark-Mode-Card-Dark, #1f1f21);
}
.Sonuclar__body__container__left {
  display: flex;
  width: 260px;
  min-width: 260px;
  height: 55px;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
}
.Sonuclar__body__container__left__close {
  min-width: 24px;
}
.Sonuclar__body__container__left__text {
  color: var(--Dark-Mode-Text-1, #fff);

  /* Body/XLarge/Bold */
  font-family: Mazzard;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-left: 4px;
}
.Sonuclar__body__container__left__alarm {
  margin: 2px 0px;
}
.Sonuclar__body__container__left_border {
  width: 2px;
  height: 55px;
  flex-shrink: 0;
  background-color: rgba(255, 255, 255, 0.1);
}
.Sonuclar__body__container__right {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  align-content: center;
  gap: 10px;
  flex-wrap: wrap;
  margin-left: auto;
}
.Sonuclar__body__button {
  display: flex !important;
  padding: 8px 24px 8px 16px !important;
  align-items: center !important;
  gap: 8px !important;

  /* Body/Large/Regular */
  font-family: Mazzard !important;
  font-size: 16px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: normal !important;
  height: 40px !important;
  width: auto !important;
  border: 0 !important;
}
.Sonuclar__body__button:hover {
  border: 0 !important;
}
.Sonuclar__body__button.success {
  color: var(--Alarm-active, #22de2a) !important;
  border-radius: 16px !important;
  background: rgba(34, 225, 41, 0.1) !important;
}
.Sonuclar__body__button.danger {
  color: var(--Alarm-passive, #e12222) !important;
  border-radius: 16px !important;
  /* border-color: #e12222; */
  background: rgba(225, 34, 34, 0.1) !important;
}
.Sonuclar__body__button.success:hover {
  background: rgba(39, 211, 44, 0.2) !important;
}
.Sonuclar__body__button.danger:hover {
  background: rgba(225, 34, 34, 0.2) !important;
}

.Sonuclar__body__button2 {
  display: flex !important;
  padding: 8px 16px !important;
  align-items: center !important;
  gap: 8px !important;

  /* Body/Large/Regular */
  font-family: Mazzard !important;
  font-size: 16px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: normal !important;
  height: 40px !important;
  width: auto !important;
  border: 1px solid #0000 !important;
}

.Sonuclar__body__button2.success {
  color: var(--Alarm-active, #22de2a) !important;
  border-radius: 16px !important;
  background: rgba(34, 225, 41, 0.1) !important;
}
.Sonuclar__body__button2.danger {
  color: var(--Alarm-passive, #e12222) !important;
  border-radius: 16px !important;
  /* border-color: #e12222; */
  background: rgba(225, 34, 34, 0.1) !important;
}
.Sonuclar__body__button2.success:hover {
  background: rgba(39, 211, 44, 0.2) !important;
  border: 1px solid #22e129 !important;
}
.Sonuclar__body__button2.danger:hover {
  background: rgba(225, 34, 34, 0.2) !important;
  border: 1px solid #e12222 !important;
}
.Sonuclar__body__button2.active.success {
  background: rgba(39, 211, 44, 0.3) !important;
  border: 1px solid #22e129 !important;
}
.Sonuclar__body__button2.active.danger {
  background: rgba(225, 34, 34, 0.3) !important;
  border: 1px solid #e12222 !important;
}
.Sonuclar__body__strateji {
  display: flex;
  height: 100%;
  padding: 16px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;
  border-radius: 16px;
  background: var(--Dark-Mode-Card-Light, #35353a);
  margin-left: 36px;
}
.Sonuclar__body__strateji__text {
  color: var(--Dark-Mode-Text-3, #797979);

  /* Body/Large/Regular */
  font-family: Mazzard;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.Sonuclar__body__strateji__puan {
  color: var(--Dark-Mode-Text-1, #fff);

  /* Body/XLarge/Semibold */
  font-family: Mazzard;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.modalSubDiv {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
}
.modalSubDiv__hr {
  height: 1px;
  border-top: 1px solid #ffffff20;
  padding-top: 15px;
  margin-top: 15px;
  width: 100%;
}
.modalSubDiv__title {
  color: var(--Dark-Mode-Text-1, #fff);
  text-align: center;

  /* Body/XLarge/Bold */
  font-family: Mazzard;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin: 0;
  padding: 0;
}
.modalSubDiv__text {
  color: var(--Dark-Mode-Text-2, #9a9a9a);
  text-align: center;

  /* Body/Normal/Regular */
  font-family: Mazzard;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin: 0;
  padding: 0;
}
.modalSelectDivUst {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  width: 100%;
}
.modalSelectDiv {
  display: flex;
  flex-direction: column;
  width: 32%;
  min-height: 50px;
  align-items: center;
  flex-shrink: 0;
  border-radius: 16px;
  background: var(--Dark-Mode-Card-Medium, #2b2b2e);
  align-self: center;
}
.modalSelectDiv.all {
  align-self: stretch;
  padding: 16px;
}
.modalSelectDiv.period {
  height: auto;
  background-color: var(--Dark-Mode-Card-Medium, #2b2b2e);
}
.modalSelectDivTop {
  display: flex;
  gap: 24px;
  align-self: stretch;
  padding: 16px;
}
.modalSelectDiv.all > .modalSelectDivTop {
  height: 40px;
  padding: 0px;
}
.modalSelectDivTop.secilen {
  border-radius: 16px;
  background: var(--Dark-Mode-Card-Medium, #35353a);
}
.modalSelectDiv__left {
  display: flex;
  align-items: center;
  gap: 8px;
  flex: 1 0 0;
  color: var(--Dark-Mode-Text-2, #9a9a9a);

  /* Body/Normal/Regular */
  font-family: Mazzard;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.modalSelectDiv__right {
  display: flex;
  align-items: center;
  gap: 24px;
  flex-shrink: 0;
}
.modalSelectDiv__right__period {
  display: flex;
  padding: 4px;
  max-width: 130px;
  align-items: center;
  gap: 4px;
  border-radius: 8px;
  background: var(--Dark-Mode-Card-Light, #444449);
  color: var(--Dark-Mode-Text-1, #fff);
  text-align: center;

  /* Body/Normal/Regular */
  font-family: Mazzard;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.modalSelectDiv__right__yon {
}
.modalSelectDiv__Bottom {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  gap: 8px;
  width: 100%;
  padding: 16px;
  background-color: var(--Dark-Mode-Card-Dark2, #2b2b2e);
}
.modalSelectDiv__periyot__item {
  display: flex;
  padding: 8px;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  background: var(--Dark-Mode-Card-Light, #35353a);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 4px;
  color: var(--Neutral-White, #fff);
  text-align: center;

  /* Body/Normal/Regular */
  font-family: Mazzard;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.modalSelectDiv__periyot__item:hover,
.modalSelectDiv__periyot__item.active {
  border-radius: 8px;
  background: var(--Brand-Main-Color, #3ab22f);
}
.modalSubDiv__footer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  gap: 24px;
  width: 100%;
  padding: 16px;
}
.modalSubDiv__footer__type {
  color: var(--Dark-Mode-Text-1, #fff);
  text-align: center;

  /* Body/Large/Medium */
  font-family: Mazzard;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  gap: 8px;
  display: flex;
}
.modalSubDiv__footer__button {
  flex-direction: row;
  display: flex;
}
.AnaKumanda__filtre {
  display: flex;
  width: 100%;
  padding: 16px;
  justify-content: flex-start;
  gap: 24px;
  align-items: center;
  border-radius: 24px;
  background: var(--Dark-Mode-Card-Medium, #2b2b2e);
  margin-bottom: 16px;
}
.AnaKumanda__filtre__click {
  display: flex;
  width: 100%;
  justify-content: flex-start;
  gap: 24px;
  align-items: center;
}
.AnaKumanda__filtre__body {
  display: flex;
  flex-direction: column;
  padding: 16px;
  align-items: flex-start;
  gap: 8px;
  border-radius: 16px;
  border: 1px solid var(--Dark-Mode-Text-2, #9a9a9a);
  background: var(--Dark-Mode-Card-Selected, #565659);
}
.AnaKumanda__filtre__title {
  color: var(--Dark-Mode-Text-2, #9a9a9a);
  text-align: center;

  /* Body/Normal/Regular */
  font-family: Mazzard;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.AnaKumanda__filtre__text {
  color: var(--Dark-Mode-Text-1, #fff);
  text-align: center;

  /* Body/XLarge/Semibold */
  font-family: Mazzard;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.AnaKumanda__filtre__container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 8px;
  min-width: 60px;
}
.AnaKumanda__filtre__top {
  color: var(--Dark-Mode-Text-1, #fff);

  /* Body/XLarge/Semibold */
  font-family: Mazzard;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  border-bottom: 1px solid #ffffff20;
  width: 100%;
}
.AnaKumanda__filtre__bottom {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
  width: 100%;
}
.AnaKumanda__filtre__bottom__left,
.AnaKumanda__filtre__bottom__right {
  display: flex;
  padding: 8px;
  align-items: center;
  gap: 8px;
  border-radius: 16px;
  background: var(--Dark-Mode-Card-Light, #35353a);
}
.AnaKumanda__filtre__bottom__left {
  align-self: stretch;
  color: var(--Dark-Mode-Text-1, #fff);

  /* Body/Normal/Regular */
  font-family: Mazzard;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.AnaKumanda__filtre__bottom__right {
  color: var(--Dark-Mode-Text-1, #fff);

  /* Body/Normal/Regular */
  font-family: Mazzard;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 14px;
}
.AnaKumanda__filtre__bottom__left__period {
  display: flex;
  padding: 4px 8px;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  background: var(--Brand-Main-Color, #3ab22f);
}
.modalAlarmDiv {
  display: flex;
  padding: 16px 24px 16px 16px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
  border-radius: 24px;
  border: 1px solid var(--Dark-Mode-Element-Divider, rgba(255, 255, 255, 0));
  background: var(
    --Dark-Mode-Gradients-Card-bgGrad,
    linear-gradient(
      180deg,
      rgba(43, 43, 46, 0.8) 0%,
      rgba(34, 36, 38, 0.32) 100%
    )
  );
}
.modalAlarmContent {
  padding: 16px 8px;
  font-size: 16px;
  text-align: center;
}
.modalAlarmButtons {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
  width: 100%;
}
.modalAlarmDiv__body {
  display: flex;
  padding: 16px;
  justify-content: space-between;
  align-items: center;
  flex: 1 0 0;
  border-radius: 16px;
  background: var(--Dark-Mode-Card-Light, #35353a);
  width: 100%;
}
.modalAlarmDiv__body__input {
  background-color: #0000;
  border: none;
}
.modalAlarmDiv__body__input:focus {
  background-color: #0000 !important;
  border: none !important;
  color: var(--Dark-Mode-Text-1, #fff) !important;
}
.modalListeTabDiv {
  display: flex;
  padding: 4px;
  justify-content: center;
  align-items: center;
  align-self: stretch;
  border-radius: 99px;
  background: #2d2d2e;
}
.modalListeTabDiv__body {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
  width: 100%;
}
.modalListeTabDiv__body__tabs {
  display: flex;
  padding: 16px;
  flex-direction: column;
  align-items: center;
  flex: 1 0 0;
}
.modalListeTabDiv__body__tabs.active {
  border-radius: 48px;
  background: #3f3f44;
}
.modalListeTabDiv__list {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 6px;
  width: 100%;
  max-height: 65vh;
}
.modalListeTabDiv__list__item {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;

  /* Body/XLarge/Semibold */
  font-family: Mazzard;
  font-size: 14px;
  font-style: normal;
  line-height: normal;
}
.modalListeTabDiv__list__item div {
  border-radius: 16px;
  border: 1px solid var(--dark-mode-element-dvider-100, rgba(255, 255, 255, 0));
  background: var(--Dark-Mode-Card-Light, #35353a);
  color: var(--Dark-Mode-Text-1, #fff);
  padding: 12px;
  width: 100%;
  border-image: linear-gradient(
    90deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 1) 50%,
    rgba(255, 255, 255, 0) 100%
  );
  border-image-slice: 1;
}
.modalAlarmDiv__body__formul {
  display: flex;
  flex-direction: column !important;
}
.modalListeScroll {
  max-height: 65vh;
  overflow-y: scroll;
}
.dahaGoster {
  padding: 12px;
  background: var(--Dark-Mode-Card-Light, #35353a);
  color: var(--Dark-Mode-Text-1, #fff);
  border-radius: 16px;
  font-size: 14px;
  font-family: Mazzard;
  height: 50px;
  width: 95px;
  display: flex;
  line-height: 14px;
  text-align: center;
}
.AnaKumanda__filtre__container_right {
  display: flex;
}
.success1 {
  background-color: #17bbb7 !important;
}
.success2 {
  background-color: #43bb19 !important;
}
@media screen and (max-width: 1000px) {
  .AramaPaneli {
    padding: 8px;
    gap: 8px;
    overflow-x: auto;
  }
  .AnaKumanda__filtre {
    padding: 8px;
    gap: 8px;
    overflow-x: auto;
  }
  .AnaKumanda__filtre__body {
    gap: 8px;
    padding: 8px;
  }
  .AnaKumanda__filtre__title,
  .AnaKumanda__filtre__top {
    font-size: 12px;
  }
  .AnaKumanda__filtre__text {
    font-size: 10px;
  }
  .AnaKumanda__filtre__bottom__left {
    font-size: 10px;

    text-wrap: nowrap;
  }
  .AnaKumanda__filtre__click {
    width: auto !important;
  }
  .AnaKumanda__filtre__container {
    min-width: auto;
  }
  .AnaKumanda__filtre__bottom__left__period {
    font-size: 10px;
    text-wrap: nowrap;
  }
  .AnaKumanda__filtre__container_right {
    display: flex;
  }
  .Sonuclar_left__title {
    font-size: 12px;
  }
  .Sonuclar_left__text {
    font-size: 12px;
  }
  .AnaKumanda__sonuc {
    margin-top: 32px;
    margin-bottom: 32px;
  }
  .Sonuclar_right__sort {
    font-size: 12px;
  }
  .Sonuclar__body {
    margin-bottom: 32px;
  }
  .Sonuclar__body__container {
    gap: 8px;
    padding: 8px;
    overflow-x: auto;
  }
  .Sonuclar__body__container__left {
    display: flex;
    width: 160px;
    min-width: 160px;
    height: 55px;
    justify-content: flex-start;
    align-items: center;
    gap: 8px;
  }
  .Sonuclar__body__container__left__text {
    font-size: 14px;
  }
  .dahaGoster {
    font-size: 10px;
    height: 25px !important;
    padding: 6px;
  }
  .Sonuclar__body__button {
    font-size: 12px !important;
    text-wrap: nowrap;
    height: 25px !important;
    padding: 8px !important;
    border-radius: 8px !important;
  }
  .Sonuclar__body__strateji {
    padding: 6px;
  }
  .Sonuclar__body__strateji__text,
  .Sonuclar__body__strateji__puan {
    font-size: 12px;
    gap: 8px;
  }
  .modalSelectDiv {
    width: 100%;
  }
  .modalSubDiv__footer {
    flex-direction: column;
    align-items: flex-start;
  }
  .modalSonucButton {
    margin-left: 0;
  }
  .DashBoard_container {
    width: 100%;
    padding: 0px !important;
  }
  .modalSelectDivUst {
    flex-direction: column;
  }
  .modalSelectDivTop {
    gap: 12px;
  }
  .modalSelectDiv__left svg {
    width: 18px;
    min-width: 18px;
  }
}
