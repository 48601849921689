.Profile_OdemeGecmisi {
  display: flex;
  padding: 32px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;
  border-radius: 32px;
  background: var(
    --Dark-Mode-Gradients-Card-bgGrad,
    linear-gradient(
      180deg,
      rgba(43, 43, 46, 0.8) 0%,
      rgba(34, 36, 38, 0.32) 100%
    )
  );
}
@media screen and (max-width: 1000px) {
  .Profile_OdemeGecmisi {
    font-size: 14px;
  }
}
