.ProfilBottom {
}
.ProfilBottom__container {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: flex-start;
}
.ProfilBottom__container__left,
.ProfilBottom__container__right {
  display: flex;
  padding: 32px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;
  border-radius: 32px;
  border: 1px solid var(--bs-table-border-color);
  background: var(
    --Dark-Mode-Gradients-Card-bgGrad,
    linear-gradient(
      180deg,
      rgba(43, 43, 46, 0.8) 0%,
      rgba(34, 36, 38, 0.32) 100%
    )
  );
}
.ProfilBottom__container__left {
  width: 50%;
}
.ProfilBottom__container__right {
  width: 49%;
}
.ProfilBottom__container__left__title {
  color: var(--Dark-Mode-Text-1, #fff);

  /* Body/XLarge/Semibold */
  font-family: Mazzard;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.PanoUser__content__item.col-lg-6 {
  width: 49% !important;
}
.ProfilBottom__container__right__top {
  display: flex;
  padding: 4px;
  justify-content: center;
  align-items: center;
  border-radius: 99px;
  background: var(--Dark-Mode-Card-Dark, #1f1f21);
}
.ProfilBottom__container__right__top__tab {
  display: flex;
  width: 222px;
  padding: 16px;
  flex-direction: column;
  align-items: center;
  color: var(--Dark-Mode-Text-4, #565659);
  text-align: center;

  /* Body/Large/Medium */
  font-family: Mazzard;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.ProfilBottom__container__right__top__tab.active {
  border-radius: 48px;
  background: var(--Dark-Mode-Card-Light, #35353a);
  color: var(--Dark-Mode-Text-1, #fff);
}
.ProfilBottom__container__right__bottom {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;
  align-self: stretch;
}
.ProfilBottom__container__right__bottom__item {
  display: flex;
  align-items: center;
  gap: 8px;
  flex: 1 0 0;
  width: 100%;
}
.ProfilBottom__container__right__bottom__item__left {
  display: flex;
  padding: 16px;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
  flex: 1 0 0;
  border-radius: 46px;
  background: var(--Dark-Mode-Card-Light, #35353a);
}
.ProfilBottom__container__right__bottom__item__left__title {
  color: var(--Dark-Mode-Text-1, #fff);

  /* Body/Large/Semibold */
  font-family: Mazzard;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.ProfilBottom__container__right__bottom__item__left__text {
  color: var(--Dark-Mode-Text-3, #797979);

  /* Body/Small/Semibold */
  font-family: Mazzard;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.ProfilBottom__container__right__bottom__item__right {
  color: var(--Dark-Mode-Text-1, #fff);

  /* Body/Large/Medium */
  font-family: Mazzard;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.ProfilBottom__content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;
  width: 100%;
}
.ProfilBottom__content__item {
  display: flex;
  width: 100%;
  padding: 16px 32px;
  justify-content: space-between;
  align-items: center;
  border-radius: 46px;
  background: var(--Dark-Mode-Card-Light, #35353a);
}
.ProfilBottom__content__item__left {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 8px;
}
.ProfilBottom__content__item__left__title {
  color: var(--Dark-Mode-Text-1, #fff);

  /* Body/Large/Semibold */
  font-family: Mazzard;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.ProfilBottom__content__item__left__desc {
  color: var(--Dark-Mode-Text-3, #797979);

  /* Body/Small/Semibold */
  font-family: Mazzard;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;

  flex-direction: row;
  display: flex;
  gap: 12px;
}
.ProfilBottom__container__right__bottom__item__left__text .whois {
  /* Body/Normal/Regular */
  font-family: Mazzard;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.ProfilBottom__container__right__bottom__item__left__text .whois span {
  font-weight: 600;
}
.ProfilBottom__container__right__bottom__item__left__text .puan {
  display: flex;
  align-items: center;
  gap: 16px;
}
.ProfilBottom__container__right__bottom__item__left__text .puan .puan__left {
  font-family: Mazzard;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.ProfilBottom__container__right__bottom__item__left__text .puan .puan__right {
  font-family: Mazzard;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
@media screen and (max-width: 1000px) {
  .ProfilBottom__container__left,
  .ProfilBottom__container__right {
    padding: 0px;
    gap: 12px;
  }
  .ProfilBottom__content__item {
    display: flex;
    width: 100%;
    padding: 12px;
    justify-content: space-between;
    align-items: center;
    border-radius: 24px;
    background: var(--Dark-Mode-Card-Light, #35353a);
  }
}
