.EgitimOdasi {
}
.EgitimOdasi_container {
  padding: 32px 0;
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 45px;
}
.EgitimOdasi_container h1 {
  color: var(--Dark-Mode-Text-1, #fff);

  /* Heading/Heading 3 */
  font-family: Mazzard;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.EgitimOdasi__header {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  gap: 16px;
}
.EgitimOdasi__header__buttons {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 16px;
  margin-left: auto;
}
.EgitimOdasi__body__item {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
}
.EgitimOdasi__body__item__title {
  color: var(--Dark-Mode-Text-1, #fff);

  /* Heading/Heading 4 */
  font-family: Mazzard;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.EgitimOdasi__body__item__body {
  display: flex;
  width: 100%;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 16px;
}
.EgitimOdasi__body__item__body__video {
  display: flex;
  max-width: 385px;
  min-width: 385px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 25px;
  flex: 1 0 0;
}
.EgitimOdasi__body__item__body__video__img {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;
}
.EgitimOdasi__body__item__body__video__img .videoIcon {
  width: 46px;
  height: 64px;
  margin-top: 24px;
  position: absolute;
}
.EgitimOdasi__body__item__body__video__img img {
  border-radius: 16px;
}
.EgitimOdasi__body__item__body__video__info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
}
.EgitimOdasi__body__item__body__video__info h2 {
  color: var(--Dark-Mode-Text-1, #fff);

  /* Heading/Heading 4 */
  font-family: Mazzard;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.EgitimOdasi__body__item__body__video__info p {
  color: var(--Dark-Mode-Text-2, #9a9a9a);

  /* Body/Large/Regular */
  font-family: Mazzard;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
@media screen and (max-width: 1000px) {
  .EgitimOdasi_container h1 {
    font-size: 24px;
  }
}
