.PanoTop {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 16px;
}
.PanoTop h4 {
  color: var(--Dark-Mode-Text-1, #fff);

  /* Heading/Heading 4 */
  font-family: Mazzard;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.PanoTop__publish {
  display: flex;
  padding: 16px 24px 16px 16px;
  align-items: center;
  gap: 32px;
  border-radius: 24px;
  border: 1px solid var(--Dark-Mode-Element-Divider, rgba(255, 255, 255, 0.08));
  background: var(--Dark-Mode-Gradients-Card-bgGrad, rgba(43, 43, 46, 0.34));
  justify-content: space-between;
}
.PanoTop__publish__left {
  display: flex;
  padding: 16px;
  align-items: center;
  gap: 8px;
  color: var(--Dark-Mode-Text-1, #fff);

  /* Body/Large/Semibold */
  font-family: Mazzard;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  border-radius: 16px;
  border: 1px solid var(--dark-mode-element-dvider-100, rgba(255, 255, 255, 0));
  border-image: linear-gradient(
    90deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 1) 50%,
    rgba(255, 255, 255, 0) 100%
  );
  border-image-slice: 1;
}
.PanoTop__publish__center {
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 8px;
  position: relative;
}
.PanoTop__publish__center img {
  position: absolute;
  left: -16px;
  top: 5px;
}
.PanoTop__publish__center__text,
.PanoTop__publish__center__points {
  color: var(--Dark-Mode-Text-4, #fff);

  /* Body/Large/Semibold */
  font-family: Mazzard;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px;
}
.PanoTop__publish__center__points {
  display: flex;
  align-items: center;
  gap: 16px;
}
.PanoTop__publish__center__text.title {
  font-size: 14px;
}
.PanoTop__publish__center__text.rank {
  color: var(--Dark-Mode-Text-4, #565659);
  padding: 12px;
  border-radius: 16px;
  border: 1px solid var(--dark-mode-element-dvider-100, rgba(255, 255, 255, 0));
  background: var(--Neutral-Gray-2, #f0f0f0);
}
.PanoTop__publish__center__degree {
  font-weight: 400;
}
.PanoTop__publish__right {
  display: flex;
  padding: 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  position: relative;
}
.PanoTop__publish__right img {
  position: absolute;
  left: -14px;
  top: 9px;
}
.PanoTop__publish__right span {
  color: var(--Neutral-Gray-9, #1f1f21);

  /* Body/Large/Semibold */
  font-family: Mazzard;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
@media screen and (max-width: 1000px) {
  .PanoTop {
    width: 100% !important;
    padding: 0 !important;
    margin: 0 !important;
  }
}
