/* App.css */

@font-face {
  font-family: "Mazzard";
  src: url("assets/fonts/MazzardFont/MazzardM-Thin.ttf") format("truetype");
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: "Mazzard";
  src: url("assets/fonts/MazzardFont/MazzardM-ExtraLight.ttf")
    format("truetype");
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: "Mazzard";
  src: url("assets/fonts/MazzardFont/MazzardM-Light.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "Mazzard";
  src: url("assets/fonts/MazzardFont/MazzardM-Regular.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Mazzard";
  src: url("assets/fonts/MazzardFont/MazzardM-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Mazzard";
  src: url("assets/fonts/MazzardFont/MazzardM-SemiBold.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "Mazzard";
  src: url("assets/fonts/MazzardFont/MazzardM-Bold.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "Mazzard";
  src: url("assets/fonts/MazzardFont/MazzardM-ExtraBold.ttf") format("truetype");
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: "Mazzard";
  src: url("assets/fonts/MazzardFont/MazzardM-Black.ttf") format("truetype");
  font-weight: 900;
  font-style: normal;
}

/* Kullanım */
body {
  font-family: "Mazzard", sans-serif;
}
body {
  background-color: var(--background-color);
  color: var(--primary-text-color);
  font-family: Mazzard, sans-serif;
  font-weight: 400;
  font-style: normal;
}
a {
  text-decoration: none;
  color: inherit;
}
a:hover {
  text-decoration: none;
  color: inherit;
}
dl,
ol,
ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
}
/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #35353a !important;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #3ab22f !important;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #25751e !important;
}
::-webkit-scrollbar:horizontal {
  height: 8px;
}
::-webkit-scrollbar-thumb:horizontal {
  border-radius: 10px;
}
/* .btn,
.btn-check:active + .btn-primary:focus,
.btn-check:checked + .btn-primary:focus,
.btn-primary.active:focus,
.btn-primary:active:focus,
.show > .btn-primary.dropdown-toggle:focus {
  box-shadow: none;
}
.btn,
.btn-check:active + .btn-primary,
.btn-check:checked + .btn-primary,
.btn-primary.active,
.btn-primary:active,
.show > .btn-primary.dropdown-toggle {
  background-color: #0000;
  border-color: #0000;
} */
.form-select,
input:-webkit-autofill,
input:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0 50px #0000 inset !important; /*your box-shadow*/
  -webkit-text-fill-color: #fff !important;
  background-color: #0000 !important;
  transition: background-color 0s 600000s, color 0s 600000s !important;
}
input:focus-visible,
input:-internal-autofill-selected,
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  border: none;
  color: var(--Dark-Mode-Text-1, #fff) !important;
  background: none !important;
  background-color: #0000 !important;
  font-family: Mazzard;
}
::-webkit-input-placeholder {
  /* WebKit, Blink, Edge */
  color: #9a9a9a !important;
}
:-moz-placeholder {
  /* Mozilla Firefox 4 to 18 */
  color: #9a9a9a !important;
  opacity: 1;
}
::-moz-placeholder {
  /* Mozilla Firefox 19+ */
  color: #9a9a9a !important;
  opacity: 1;
}
:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #9a9a9a !important;
}
::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #9a9a9a !important;
}

::placeholder {
  /* Most modern browsers support this now. */
  color: #9a9a9a !important;
}
.form-label {
  color: var(--Dark-Mode-Input-Label, #fff);

  /* Body/Normal/Medium */
  font-family: Mazzard;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.form-select,
.form-select:focus,
.form-control,
.form-control:focus {
  border: none !important;
  color: var(--Dark-Mode-Text-1, #fff) !important;
  background: none !important;
  font-family: Mazzard;
}

.height100 {
  height: 100% !important;
}
.btn {
  display: flex !important;
  width: 100% !important;
  height: 56px !important;
  padding: 8px 32px !important;
  justify-content: center !important;
  align-items: center !important;
  gap: 8px !important;
  align-self: stretch !important;
  border: none !important;
  border-radius: 86px !important;
}
.btn-primary {
  border-radius: 86px !important;
  border: 1px solid var(--Brand-Main-Color, #3ab22f) !important;
  background: linear-gradient(180deg, #3ab22f 0%, #237f1a 100%) !important;
}
.btn-primary:hover {
  border-radius: 86px !important;
  border: 0px solid var(--Brand-Main-Color, #237f1a) !important;
  background-color: var(--Brand-Main-Color, #3ab22f) !important;
}
.btn-outline-primary {
  display: flex !important;
  height: 56px !important;
  padding: 8px 32px !important;
  justify-content: center !important;
  align-items: center !important;
  gap: 8px !important;
  color: #3ab22f !important;
  flex-shrink: 0 !important;
  border-radius: 86px !important;
  border: 1px solid var(--Brand-Main-Color, #3ab22f) !important;
}
.btn-outline-primary:hover {
  border-radius: 86px !important;
  color: #3ab22f !important;
  border: 1px solid var(--Brand-Main-Color, #3ab22f) !important;
  background: rgba(58, 178, 47, 0.2) !important;
}
.tradingview-widget-copyright {
  display: none !important;
}
.tradingview-widget-copyright a {
  text-decoration: none !important;
  color: #b2b5be !important;
}
@keyframes modal-video {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes modal-video-inner {
  from {
    transform: translate(0, 100px);
  }

  to {
    transform: translate(0, 0);
  }
}

.modal-video {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000000;
  cursor: pointer;
  opacity: 1;
  animation-timing-function: ease-out;
  animation-duration: 0.3s;
  animation-name: modal-video;
  -webkit-transition: opacity 0.3s ease-out;
  -moz-transition: opacity 0.3s ease-out;
  -ms-transition: opacity 0.3s ease-out;
  -o-transition: opacity 0.3s ease-out;
  transition: opacity 0.3s ease-out;
}

.modal-video-effect-exit {
  opacity: 0;
}

.modal-video-effect-exit .modal-video-movie-wrap {
  -webkit-transform: translate(0, 100px);
  -moz-transform: translate(0, 100px);
  -ms-transform: translate(0, 100px);
  -o-transform: translate(0, 100px);
  transform: translate(0, 100px);
}

.modal-video-body {
  max-width: 960px;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  padding: 0 10px;
  display: flex;
  justify-content: center;
  box-sizing: border-box;
}

.modal-video-inner {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

@media (orientation: landscape) {
  .modal-video-inner {
    padding: 10px 60px;
    box-sizing: border-box;
  }
}

.modal-video-movie-wrap {
  width: 100%;
  height: 0;
  position: relative;
  padding-bottom: 56.25%;
  background-color: #333;
  animation-timing-function: ease-out;
  animation-duration: 0.3s;
  animation-name: modal-video-inner;
  -webkit-transform: translate(0, 0);
  -moz-transform: translate(0, 0);
  -ms-transform: translate(0, 0);
  -o-transform: translate(0, 0);
  transform: translate(0, 0);
  -webkit-transition: -webkit-transform 0.3s ease-out;
  -moz-transition: -moz-transform 0.3s ease-out;
  -ms-transition: -ms-transform 0.3s ease-out;
  -o-transition: -o-transform 0.3s ease-out;
  transition: transform 0.3s ease-out;
}

.modal-video-movie-wrap iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.modal-video-close-btn {
  position: absolute;
  z-index: 2;
  top: -45px;
  right: 0px;
  display: inline-block;
  width: 35px;
  height: 35px;
  overflow: hidden;
  border: none;
  background: rgba(0, 0, 0, 0);
}

@media (orientation: landscape) {
  .modal-video-close-btn {
    top: 0;
    right: -45px;
  }
}

.modal-video-close-btn:before {
  transform: rotate(45deg);
}

.modal-video-close-btn:after {
  transform: rotate(-45deg);
}

.modal-video-close-btn:before,
.modal-video-close-btn:after {
  content: "";
  position: absolute;
  height: 2px;
  width: 100%;
  top: 50%;
  left: 0;
  margin-top: -1px;
  background: #fff;
  border-radius: 5px;
  margin-top: -6px;
}
.modal {
  border-radius: 24px;
  box-shadow: 0px 26px 56px 0px rgba(0, 0, 0, 0.16),
    0px 102px 102px 0px rgba(0, 0, 0, 0.14),
    0px 230px 138px 0px rgba(0, 0, 0, 0.08),
    0px 409px 164px 0px rgba(0, 0, 0, 0.02),
    0px 639px 179px 0px rgba(0, 0, 0, 0);
}
.modal-content {
  display: flex !important;
  padding: 24px !important;
  flex-direction: column !important;
  align-items: flex-start !important;
  gap: 16px !important;
  border-radius: 24px !important;
  border-color: var(--pcolor, #1f1f21) !important;
  background: var(--Dark-Mode-Card-Dark, #1f1f21) !important;
  box-shadow: 0px 26px 56px 0px rgba(0, 0, 0, 0.16),
    0px 102px 102px 0px rgba(0, 0, 0, 0.14),
    0px 230px 138px 0px rgba(0, 0, 0, 0.08),
    0px 409px 164px 0px rgba(0, 0, 0, 0.02),
    0px 639px 179px 0px rgba(0, 0, 0, 0);
}

.btn-close {
  color: #ffffff;
  background-image: url("assets/img/dark-close.png") !important;
  opacity: 1;
}
.modal-30w {
  width: 30% !important;
  max-width: none !important;
}
.modal-40w {
  width: 40% !important;
  max-width: none !important;
}
.modal-50w {
  width: 50% !important;
  max-width: none !important;
}
.modal-787w {
  width: 787px !important;
  max-width: none !important;
}
.modal-863w {
  width: 863px !important;
  max-width: none !important;
}
.modal-80w {
  width: 80% !important;
  max-width: none !important;
}
.modal-65w {
  width: 65% !important;
  max-width: none !important;
}
.modal-header {
  width: 100% !important;
  border: 0 !important;
  padding: 0 !important;
}
.modal-backdrop.show {
  opacity: 0.5 !important;
  --bs-backdrop-bg: #1f1f21 !important;
}
.modal-title {
  color: var(--Dark-Mode-Text-1, #fff) !important;

  /* Body/XLarge/Semibold */
  font-family: Mazzard;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  border-bottom: 1px solid #ffffff20;
}
.modal-title i {
  font-style: italic !important;
  color: #3ab22f !important;
}
.modal-body {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  width: 100%;
}
.modalSelecteds,
.modalSelectButtons {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  flex-direction: row;
  gap: 8px;
}
.modalSelectButtons {
  max-height: calc(60vh);
  overflow-y: auto;
}
.modalSelectButton {
  display: flex !important;
  width: auto !important;
  font-family: Mazzard !important;
  font-size: 14px !important;
  align-items: center !important;
  justify-content: center !important;
  text-align: center !important;
  padding: 12px !important;
  height: auto !important;
  border-radius: 16px !important;
  border: 0 !important;
  color: var(--Dark-Mode-Text-1, #fff) !important;
  background: var(--Dark-Mode-Card-Light, #35353a) !important;
  position: relative !important;
}
.modalSelectButton:hover {
  border-radius: 16px !important;
  background: var(--Brand-Main-Color, #3ab22f) !important;
}
.modalSelectButton.active {
  border-radius: 16px !important;
  background: var(--Brand-Main-Color, #3ab22f) !important;
}
.modalSelectButton__close {
  position: absolute !important;
  right: -8px !important;
  top: 0 !important;
  border-radius: 45px !important;
  background: var(--Dark-Mode-Card-Medium, #2b2b2e) !important;
}
.modalSelectButton__close svg {
  fill: var(--Dark-Mode-svg, #2b2b2e) !important;
}
.modalSearch {
  display: flex;
}
.modalSearch__input {
  display: flex;
  width: 216px;
  padding: 16px 24px 16px 16px;
  align-items: center;
  gap: 16px;
  border-radius: 16px;
  background: var(--Dark-Mode-Card-Light, #35353a);
  color: var(--Dark-Mode-Input-Placeholder, #9a9a9a);
  text-align: left;

  /* Body/Normal/Regular */
  font-family: Mazzard;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.modal-header .btn-close {
  background-color: var(--Dark-Mode-close, #fff) !important;
  padding: 0.5rem 0.5rem;
  margin: -0.5rem -0.5rem -0.5rem auto;
  right: 35px;
  position: absolute;
  top: 20px;
}
.modalSonucButton {
  color: var(--Neutral-White, #fff) !important;

  /* Body/Large/Semibold */
  font-family: Mazzard !important;
  font-size: 16px !important;
  font-style: normal !important;
  font-weight: 600 !important;
  line-height: normal !important;
  display: flex !important;
  width: 196px !important;
  height: 42px !important;
  padding: 8px 32px !important;
  justify-content: center !important;
  align-items: center !important;
  gap: 8px !important;
  margin-left: auto;
}
.modalAlarmDivButtons {
  display: flex;
  flex-direction: row;
  gap: 8px;
}
.ml-auto {
  margin-left: auto;
}
.mr-auto {
  margin-right: auto;
}
@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.loading-spinner {
  width: 30px;
  height: 30px;
  border: 10px solid #f3f3f3; /* Light grey */
  border-top: 10px solid #383636; /* Blue */
  border-radius: 50%;
  animation: lds-spinner 1.5s linear infinite;
}

.spinner-container {
  display: grid;
  justify-content: center;
  align-items: center;
  height: 350px;
}

.modalSelectDivs {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  flex-direction: row;
  gap: 16px;
}
.flex-row {
  flex-direction: row;
}
.flex-column {
  flex-direction: column;
}
.flex-row-reverse {
  flex-direction: row-reverse;
}
.flex-column-reverse {
  flex-direction: column-reverse;
}
.gap16 {
  gap: 16px;
}
.gap24 {
  gap: 24px;
}
.opacity05 {
  opacity: 0.5;
}
.op5 {
  opacity: 0.5;
}
.ytp-watermark,
.ytp-show-cards-title {
  display: none !important;
}
.whiteText p {
  color: white !important;
}

@media screen and (max-width: 1000px) {
  .container,
  .container-md,
  .container-sm {
    max-width: 900px !important;
  }
  .modal-30w {
    width: 100% !important;
    max-width: none !important;
  }
  .modal-40w {
    width: 100% !important;
    max-width: none !important;
  }
  .modal-50w {
    width: 100% !important;
    max-width: none !important;
  }
  .modal-787w {
    width: 100% !important;
    max-width: none !important;
  }
  .modal-863w {
    width: 100% !important;
    max-width: none !important;
  }
  .modal-80w {
    width: 100% !important;
    max-width: none !important;
  }
  .modal-65w {
    width: 100% !important;
    max-width: none !important;
  }
  .modal-dialog {
    margin: 0 !important;
    padding: 0.5rem !important;
  }
}
