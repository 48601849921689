.Pano {
  background-image: url("../..//assets/img/sky-pattern.png") !important;
  background-repeat: no-repeat !important;
  background-size: cover !important;
}
.Pano_container {
  background-image: url("../../assets/img/bg-footer.png") !important;
  background-repeat: no-repeat !important;
  background-size: 100% !important;
  background-position: bottom;
  padding-bottom: 150px;
}
.Pano .DashBoard_container {
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 32px;
  width: 100%;
}
.Pano .DashBoard_container .row {
  width: 100%;
}
@media screen and (max-width: 1000px) {
  .Pano .DashBoard_container .row {
    width: 100%;
    padding: 0 !important;
    margin: 0 !important;
  }
}
