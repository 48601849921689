.Alarmlar__container {
}
.Alarmlar__header {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}
.Alarmlar__header__left {
  display: flex;
  padding: 16px;
  justify-content: flex-start;
  align-items: center;
  width: 250px;
}
.Alarmlar__header__right {
  display: flex;
  padding: 16px;
  justify-content: flex-end;
  align-items: center;
  width: 250px;
}
.Alarmlar__header__tabs {
  display: inline-flex;
  padding: 4px;
  justify-content: center;
  align-items: center;
  border-radius: 99px;
  background: var(--Dark-Mode-Card-Dark, #1f1f21);
}
.Alarmlar__header__tab {
  color: var(--Dark-Mode-Text-4, #565659);

  display: flex;
  width: 196px;
  padding: 16px;
  flex-direction: column;
  align-items: center;

  /* Body/Large/Bold */
  font-family: Mazzard;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.Alarmlar__header__tab.active {
  border-radius: 48px;
  background: var(--Dark-Mode-Card-Light, #35353a);
  color: var(--Dark-Mode-Text-1, #fff);
}
.Alarmlar__header__body {
  display: flex;
  width: 100%;
  padding: 24px;
  flex-direction: column;
  align-items: center;
  gap: 16px;
}
.Alarmlar__item {
  display: flex;
  padding: 16px 24px;
  align-items: center;
  justify-content: space-between;
  gap: 64px;
  align-self: stretch;
  border-radius: 24px;
  background: var(--Dark-Mode-Card-Medium, #2b2b2e);
}
.Alarmlar__item__left {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.Alarmlar__item__left__title {
  padding: 16px;
  font-family: Mazzard;
  border-radius: 16px;
  border: 1px solid var(--Dark-Mode-Element-Divider, rgba(255, 255, 255, 0));
  background: var(--Dark-Mode-Card-Light, #35353a);
  max-width: 300px;
  min-width: 300px;
  line-height: normal;
  white-space: nowrap;
  width: 50px;
  overflow: hidden;
  text-overflow: ellipsis;
}
.Alarmlar__item__left__title span {
  color: var(--Dark-Mode-Text-1, #fff);
  font-family: Mazzard;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.Alarmlar__item__left__text {
  display: flex;
  flex-direction: row;
  font-family: Mozzard;
  font-size: 14px;
  flex-wrap: wrap;
  line-height: normal;
}
.Alarmlar__item__left__text div {
  padding: 0 5px;
  font-family: Mazzard;
  font-size: 14px;
  line-height: normal;
}
.Alarmlar__item__right {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
}
.Alarmlar__item__btn {
  display: flex;
  width: 222px;
  height: 70px;
  padding: 16px 24px 16px 16px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 2px;
  border-radius: 16px;
  background: linear-gradient(180deg, #22e129 0%, rgba(34, 225, 41, 0.28) 100%);
}
.Alarmlar__item__btn.warning {
  background: linear-gradient(180deg, #f67600 0%, #b35600 100%);
}
.Alarmlar__item__btn.passive {
  background: linear-gradient(180deg, #e12222 0%, rgba(225, 34, 34, 0.28) 100%);
}
.Alarmlar__item__btn__top {
  color: var(--Dark-Mode-Text-1, #fff);

  /* Body/Large/Regular */
  font-family: Mazzard;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.Alarmlar__item__btn__bottom {
  color: var(--Dark-Mode-Text-1, #fff);

  /* Body/XLarge/Semibold */
  font-family: Mazzard;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
