.Strateji-btnGroup {
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  gap: 10px;
  flex-wrap: wrap;
  margin-left: auto;
}
.Strateji-btnGroup .btn {
  width: auto !important;
  height: auto !important;
}
.Strateji-btnGroup .btn-outline-secondary {
  border: 1px solid #6c757d !important;
}
.success1 {
  background-color: #17bbb7 !important;
}
.success2 {
  background-color: #43bb19 !important;
}
.danger1 {
  background-color: #ff8b00 !important;
}
.danger2 {
  background-color: #9c27b0 !important;
}
