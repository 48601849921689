.PanoBottom {
}
.PanoBottom__container {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: flex-start;
}
.PanoBottom__container__left,
.PanoBottom__container__right {
  display: flex;
  padding: 20px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;
  border-radius: 32px;
  background: var(
    --Dark-Mode-Gradients-Card-bgGrad,
    linear-gradient(
      180deg,
      rgba(43, 43, 46, 0.8) 0%,
      rgba(34, 36, 38, 0.32) 100%
    )
  );
}
.PanoBottom__container__left {
  width: 60%;
}
.PanoBottom__container__right {
  width: 39%;
}
.PanoBottom__container__left__title {
  color: var(--Dark-Mode-Text-1, #fff);

  /* Body/XLarge/Semibold */
  font-family: Mazzard;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.PanoUser__content__item.col-lg-6 {
  width: 49% !important;
}
.PanoBottom__container__right__top {
  display: flex;
  padding: 4px;
  justify-content: center;
  align-items: center;
  border-radius: 99px;
  background: var(--Dark-Mode-Card-Dark, #1f1f21);
}
.PanoBottom__container__right__top__tab {
  display: flex;
  width: 222px;
  padding: 16px;
  flex-direction: column;
  align-items: center;
  color: var(--Dark-Mode-Text-4, #565659);
  text-align: center;

  /* Body/Large/Medium */
  font-family: Mazzard;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.PanoBottom__container__right__top__tab.active {
  border-radius: 48px;
  background: var(--Dark-Mode-Card-Light, #35353a);
  color: var(--Dark-Mode-Text-1, #fff);
}
.PanoBottom__container__right__bottom {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;
  align-self: stretch;
}
.PanoBottom__container__right__bottom__item {
  display: flex;
  align-items: center;
  gap: 8px;
  flex: 1 0 0;
  width: 100%;
}
.PanoBottom__container__right__bottom__item__left {
  display: flex;
  padding: 16px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
  flex: 1 0 0;
  border-radius: 46px;
  background: var(--Dark-Mode-Card-Light, #35353a);
}
.PanoBottom__container__right__bottom__item__left__title {
  color: var(--Dark-Mode-Text-1, #fff);

  /* Body/Large/Semibold */
  font-family: Mazzard;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.PanoBottom__container__right__bottom__item__left__text {
  color: var(--Dark-Mode-Text-3, #797979);

  /* Body/Small/Semibold */
  font-family: Mazzard;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.PanoBottom__container__right__bottom__item__right {
  color: var(--Dark-Mode-Text-1, #fff);

  /* Body/Large/Medium */
  font-family: Mazzard;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
