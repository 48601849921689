.DashBoard {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 24px;
  flex-shrink: 0;
  background-image: url("../img/dashboard.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top;
  margin: 28px 60px;
  height: 777px;
  width: 1285px;
}
.DashBoard__content {
  display: flex;
  width: 100%;
  padding: 48px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 24px;
}
.DashBoard__content h2 {
  color: var(--Dark-Mode-Text-1, #fff);

  /* Heading/Heading 2 */
  font-family: Mazzard;
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.DashBoard__content p {
  color: var(--Dark-Mode-Text-1, #fff);
  text-align: left;

  /* Body/XLarge/Regular */
  font-family: Mazzard;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.DashBoard__content__video {
  width: 100%;
  justify-content: space-around;
  align-items: center;
  display: flex;
  flex-direction: row;
}
.DashBoard__content__video .btn {
  border-radius: 86px;
  background: linear-gradient(180deg, #3ab22f 0%, #237f1a 100%);
}
@media screen and (max-width: 1000px) {
  .DashBoard {
    width: 100%;
    height: 100%;
    margin: 0;
  }
  .DashBoard__content {
    width: 100%;
    padding: 48px 24px;
  }
}
