.SideMenu {
  background-image: var(--logo-pattern);
  background-repeat: no-repeat;
  background-size: 100%;
  background-position: top;
  padding-bottom: 32px;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  flex-shrink: 0;
}
.SideMenu__logo {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding: 18px 0;
}
.SideMenu_top {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 16px 0;
}
.SideMenu__logo__bottom {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 16px 0;
  max-width: 255px;
}
.SideMenu__container {
}
.SideMenu__profil {
  border-radius: 16px;
  border: 3px solid rgba(255, 255, 255, 0.45);
  background: linear-gradient(180deg, #f28d63 0%, #e65d47 100%);
  flex-direction: row;
  display: flex;
  justify-content: space-between;
  margin: 0 8px;
}
.SideMenu__profil__text {
  display: flex;
  flex-direction: column;
  padding: 8px;
}
.SideMenu__profil__text__title {
  font-family: "Mazzard";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 28px;
  color: #ffffff;
  text-overflow: ellipsis;
  width: 150px;
  overflow: hidden;
  white-space: nowrap;
}
.SideMenu__profil__text__subtitle {
  font-family: "Mazzard";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #ffffff;
}
.SideMenu__profil__icon {
  justify-content: space-between;
  align-items: center;
  display: flex;
  margin-right: 30px;
}
.SideMenu__menu__link {
  display: flex;
  padding: 8px 16px;
  align-items: center;
  align-self: stretch;
  border-left: 6px solid transparent;
  color: var(--Dark-Mode-Text-2, #fff);
}
.SideMenu__menu__link__text {
  /* Body/Large/Regular */
  font-family: Mazzard;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.SideMenu__menu__link__icon {
  align-items: normal;
  display: flex;
  margin-right: 10px;
  height: 25px;
}
.SideMenu__menu__link__icon svg {
  fill: var(--Dark-Mode-svg);
}

.SideMenu__menu__link:hover,
.SideMenu__menu__link.active {
  color: #3ab22f;
  fill: #3ab22f;
  border-left: 6px solid var(--Brand-Main-Color, #3ab22f);
}
.SideMenu__menu__link:hover .SideMenu__menu__link__icon svg,
.SideMenu__menu__link.active .SideMenu__menu__link__icon svg {
  fill: #3ab22f;
}
.SideMenu__submenu {
  display: none;
}
.SideMenu__submenu.show {
  display: block;
}
.SideMenu__submenu__list li a {
  color: var(--Dark-Mode-Text-2, #9a9a9a);

  /* Body/Large/Regular */
  font-family: Mazzard;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-left: 32px;
}
.SideMenu__submenu__list li a:hover {
  color: var(--Dark-Mode-Text-1, #fff);
}
.Sidemenu_bottom {
  gap: 18px;
  flex-direction: column;
  justify-content: space-between;
  display: flex;

  max-width: 255px;
}
.dMobileMenu {
}
.Sidemenu__alarm {
  border-radius: 16px;
  border: 1px solid var(--Neutral-Gray-5, #565659);
  background: #8f8f8f;
  background-image: url("../../assets/img/sidemenu_alarm.png");
  background-repeat: no-repeat;
  background-size: 100%;
  background-position: top;
  display: flex;
  padding: 16px 24px;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
}
.Sidemenu__alarm.success {
  border-radius: 16px;
  border: 1px solid rgba(34, 222, 42, 0.2);
  background: #11c819;
  background-image: url("../../assets/img/sidemenu_alarm.png");
  box-shadow: 0px 0px 12px 0px rgba(33, 213, 41, 0.2);
}
.Sidemenu__alarm.danger {
  border-radius: 16px;
  border: 1px solid rgba(225, 34, 34, 0.2);
  background: #e12222;
  background-image: url("../../assets/img/sidemenu_alarm.png");
  box-shadow: 0px 0px 12px 0px rgba(33, 213, 41, 0.2);
}
.Sidemenu__alarm.warning {
  border-radius: 16px;
  border: 1px solid rgba(246, 118, 0, 0.2);
  background: #f67600;
  background-image: url("../../assets/img/sidemenu_alarm.png");
  box-shadow: 0px 0px 12px 0px rgba(33, 213, 41, 0.2);
}
.Sidemenu__alarm.gray {
  border-radius: 16px;
  border: 1px solid #d3d3d3;
  background: #efefef;
  background-image: url("../../assets/img/sidemenu_alarm.png");
  box-shadow: 0px 0px 12px 0px rgba(33, 213, 41, 0.2);
}
.Sidemenu__alarm__icontext {
  display: flex;
  align-items: center;
}
.Sidemenu__alarm__icon {
  align-items: normal;
  display: flex;
  margin-right: 10px;
}
.Sidemenu__alarm__text {
  color: var(--Dark-Mode-Text-1, #fff);
  font-family: Mazzard;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding-top: 5px;
}
.Sidemenu__alarm__count {
  display: flex;
  width: 24px;
  height: 24px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  color: var(--Dark-Mode-Text-1, #fff);
  border-radius: 45px;
  background: rgba(255, 255, 255, 0.1);
}
.Sidemenu__package {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  background-repeat: no-repeat;
  background-size: 100%;
  background-position: top;
}
.Sidemenu__package.premiumPlus {
  border-radius: 24px;
  background-image: url("../../assets/img/premium+.png");
}
.Sidemenu__package.premium {
  border-radius: 24px;
  background-image: url("../../assets/img/premium.png");
}
.Sidemenu__package__div {
  display: flex;
  height: 96px;
  padding: 16px 24px;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
}
.Sidemenu__package__left {
}
.Sidemenu__package__left__top {
  color: var(--Dark-Mode-Text-1, #fff);

  /* Body/Small/Regular */
  font-family: Mazzard;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.Sidemenu__package__left__bottom {
  color: var(--Dark-Mode-Text-1, #fff);

  /* Body/XLarge/Semibold */
  font-family: Mazzard;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.Sidemenu__package__right {
}
.Sidemenu__package__right__top {
  color: var(--Dark-Mode-Text-1, #fff);

  /* Body/Small/Regular */
  font-family: Mazzard;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.Sidemenu__package__right__bottom {
  color: var(--Dark-Mode-Text-1, #fff);

  /* Body/XLarge/Semibold */
  font-family: Mazzard;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.Sidemenu__theme {
  display: flex;
  padding: 0px 24px;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  align-self: stretch;
}
.Sidemenu__theme__top {
  display: flex;
  align-items: center;
  gap: 8px;
}
.Sidemenu__theme__text {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 2px;
}
.Sidemenu__theme__text__topspan {
  color: var(--Dark-Mode-Text-2, #9a9a9a);

  /* Body/XSmall/Medium */
  font-family: Mazzard;
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.Sidemenu__theme__text__bottomspan {
  color: var(--Dark-Mode-Text-1, #fff);

  /* Body/Large/Medium */
  font-family: Mazzard;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.Sidemenu__theme__icon {
}
.Sidemenu__theme__bottom {
  color: var(--Dark-Mode-Text-4, #474747);

  /* Body/XSmall/Medium */
  font-family: Mazzard;
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.Sidemenu__theme__cikis {
  display: flex;
  padding: 12px 0;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
}
.Sidemenu__theme__cikis__link {
  color: var(--Dark-Mode-Text-3, #6c6c6c);

  /* Body/Large/Regular */
  font-family: Mazzard;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.Sidemenu__theme__cikis__link:hover {
  color: var(--Dark-Mode-Text-1, #fff);
}
.Sidemenu__theme__cikis__link svg {
  fill: #6c6c6c;
}

.Sidemenu__theme__cikis__link:hover svg {
  fill: #fff;
}
.bell {
  display: block;
  width: 40px;
  height: 40px;
  font-size: 40px;
  margin: 50px auto 0;
  color: #9e9e9e;
  -webkit-animation: ring 4s 0.7s ease-in-out infinite;
  -webkit-transform-origin: 50% 4px;
  -moz-animation: ring 4s 0.7s ease-in-out infinite;
  -moz-transform-origin: 50% 4px;
  animation: ring 4s 0.7s ease-in-out infinite;
  transform-origin: 50% 4px;
}

@-webkit-keyframes ring {
  0% {
    -webkit-transform: rotateZ(0);
  }
  1% {
    -webkit-transform: rotateZ(30deg);
  }
  3% {
    -webkit-transform: rotateZ(-28deg);
  }
  5% {
    -webkit-transform: rotateZ(34deg);
  }
  7% {
    -webkit-transform: rotateZ(-32deg);
  }
  9% {
    -webkit-transform: rotateZ(30deg);
  }
  11% {
    -webkit-transform: rotateZ(-28deg);
  }
  13% {
    -webkit-transform: rotateZ(26deg);
  }
  15% {
    -webkit-transform: rotateZ(-24deg);
  }
  17% {
    -webkit-transform: rotateZ(22deg);
  }
  19% {
    -webkit-transform: rotateZ(-20deg);
  }
  21% {
    -webkit-transform: rotateZ(18deg);
  }
  23% {
    -webkit-transform: rotateZ(-16deg);
  }
  25% {
    -webkit-transform: rotateZ(14deg);
  }
  27% {
    -webkit-transform: rotateZ(-12deg);
  }
  29% {
    -webkit-transform: rotateZ(10deg);
  }
  31% {
    -webkit-transform: rotateZ(-8deg);
  }
  33% {
    -webkit-transform: rotateZ(6deg);
  }
  35% {
    -webkit-transform: rotateZ(-4deg);
  }
  37% {
    -webkit-transform: rotateZ(2deg);
  }
  39% {
    -webkit-transform: rotateZ(-1deg);
  }
  41% {
    -webkit-transform: rotateZ(1deg);
  }

  43% {
    -webkit-transform: rotateZ(0);
  }
  100% {
    -webkit-transform: rotateZ(0);
  }
}

@-moz-keyframes ring {
  0% {
    -moz-transform: rotate(0);
  }
  1% {
    -moz-transform: rotate(30deg);
  }
  3% {
    -moz-transform: rotate(-28deg);
  }
  5% {
    -moz-transform: rotate(34deg);
  }
  7% {
    -moz-transform: rotate(-32deg);
  }
  9% {
    -moz-transform: rotate(30deg);
  }
  11% {
    -moz-transform: rotate(-28deg);
  }
  13% {
    -moz-transform: rotate(26deg);
  }
  15% {
    -moz-transform: rotate(-24deg);
  }
  17% {
    -moz-transform: rotate(22deg);
  }
  19% {
    -moz-transform: rotate(-20deg);
  }
  21% {
    -moz-transform: rotate(18deg);
  }
  23% {
    -moz-transform: rotate(-16deg);
  }
  25% {
    -moz-transform: rotate(14deg);
  }
  27% {
    -moz-transform: rotate(-12deg);
  }
  29% {
    -moz-transform: rotate(10deg);
  }
  31% {
    -moz-transform: rotate(-8deg);
  }
  33% {
    -moz-transform: rotate(6deg);
  }
  35% {
    -moz-transform: rotate(-4deg);
  }
  37% {
    -moz-transform: rotate(2deg);
  }
  39% {
    -moz-transform: rotate(-1deg);
  }
  41% {
    -moz-transform: rotate(1deg);
  }

  43% {
    -moz-transform: rotate(0);
  }
  100% {
    -moz-transform: rotate(0);
  }
}

@keyframes ring {
  0% {
    transform: rotate(0);
  }
  1% {
    transform: rotate(30deg);
  }
  3% {
    transform: rotate(-28deg);
  }
  5% {
    transform: rotate(34deg);
  }
  7% {
    transform: rotate(-32deg);
  }
  9% {
    transform: rotate(30deg);
  }
  11% {
    transform: rotate(-28deg);
  }
  13% {
    transform: rotate(26deg);
  }
  15% {
    transform: rotate(-24deg);
  }
  17% {
    transform: rotate(22deg);
  }
  19% {
    transform: rotate(-20deg);
  }
  21% {
    transform: rotate(18deg);
  }
  23% {
    transform: rotate(-16deg);
  }
  25% {
    transform: rotate(14deg);
  }
  27% {
    transform: rotate(-12deg);
  }
  29% {
    transform: rotate(10deg);
  }
  31% {
    transform: rotate(-8deg);
  }
  33% {
    transform: rotate(6deg);
  }
  35% {
    transform: rotate(-4deg);
  }
  37% {
    transform: rotate(2deg);
  }
  39% {
    transform: rotate(-1deg);
  }
  41% {
    transform: rotate(1deg);
  }

  43% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(0);
  }
}
.SideMenu__menu__toggler {
  display: none;
}
@media screen and (max-width: 1000px) {
  .SideMenu {
    padding: 0;
  }
  .dMobileMenu {
    display: none;
  }
  .SideMenu__logo {
    justify-content: space-around;
  }
  .SideMenu__menu__toggler {
    display: block;
  }
}
