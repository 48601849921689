.PanoFormul {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 16px;
}
.PanoFormul__container {
  display: flex;
  width: 100%;
  padding-top: 24px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 16px;
  border-radius: 32px;
  padding: 32px;
  background: var(
    --Dark-Mode-Gradients-Card-bgGrad,
    linear-gradient(
      180deg,
      rgba(43, 43, 46, 0.8) 0%,
      rgba(34, 36, 38, 0.32) 100%
    )
  );
}
.PanoFormul h3 {
  color: var(--Dark-Mode-Text-1, #fff);

  /* Heading/Heading 3 */
  font-family: Mazzard;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.PanoFormul__content__skelaton,
.PanoFormul__content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: flex-start;
  flex-wrap: wrap;
  gap: 10px;
}
.PanoFormul__content__skelaton span {
  display: flex;
  width: 232px;
  height: 243px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 32px;
}
.PanoFormul__content__item {
  display: flex;
  width: 232px;
  height: 243px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.PanoFormul__content__item__top {
}
.PanoFormul__content__item__bottom {
  display: flex;
  height: 195px;
  width: 100%;
  padding: 24px 12px;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  flex-shrink: 0;
  border-radius: 32px;
}
.PanoFormul__content__item__bottom.bgregular {
  color: var(--Dark-Mode-Text-1, #fff);
  background: var(--Dark-Mode-Card-Medium, #2b2b2e);
}
.PanoFormul__content__item__bottom.bg1 {
  border: 2px solid var(--Gradients-Store-ranking, rgba(255, 255, 255, 0.43));
  background: var(
    --Gradients-Gold-1st,
    linear-gradient(180deg, #ffc700 0%, #fff500 100%)
  );
  color: #1f1f21;
}
.PanoFormul__content__item__bottom.bg2 {
  border: 2px solid var(--Gradients-Store-ranking, rgba(255, 255, 255, 0.43));
  background: var(
    --Gradients-2nd,
    linear-gradient(180deg, #ffa017 0%, #ff7817 100%)
  );
  color: #1f1f21;
}
.PanoFormul__content__item__bottom.bg3 {
  border: 2px solid var(--Gradients-Store-ranking, rgba(255, 255, 255, 0.43));
  background: var(
    --Gradients-3rd,
    linear-gradient(180deg, #aeaeae 0%, #ececec 100%)
  );
  color: #1f1f21;
}
.PanoFormul__content__item__bottom__top {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
  flex: 1 0 0;
}
.PanoFormul__content__item__bottom .sort {
  /* Body/Large/Medium */
  font-family: Mazzard;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.PanoFormul__content__item__bottom .title {
  /* Body/XLarge/Bold */
  font-family: Mazzard;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.PanoFormul__content__item__bottom .whois {
  /* Body/Normal/Regular */
  font-family: Mazzard;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.PanoFormul__content__item__bottom .whois span {
  font-weight: 600;
}
.PanoFormul__content__item__bottom .puan {
  display: flex;
  align-items: center;
  gap: 16px;
  font-family: Mazzard;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.PanoFormul__content__item__bottom.bgregular .title {
  color: var(--Dark-Mode-Text-1, #fff);
}
.PanoFormul__content__item__bottom.bgregular .whois,
.PanoFormul__content__item__bottom.bgregular .puan .puan__left,
.PanoFormul__content__item__bottom.bgregular .puan .puan__right {
  color: #9a9a9a;
}

.PanoFormul__content__item__bottom__bottom {
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
}
.PanoFormul__content__item__bottom__bottom__text {
  /* Body/Small/Regular */
  font-family: Mazzard;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.PanoFormul__content__item__bottom.bgregular
  .PanoFormul__content__item__bottom__bottom__text {
  color: var(--Dark-Mode-Text-1, #fff);
}
.PanoFormul__bottom {
  display: flex;
  padding: 16px 0px;
  justify-content: center;
  align-items: flex-start;
  gap: 4px;
  align-self: stretch;
}
.PanoFormul__bottom__text {
  color: var(--Dark-Mode-Text-1, #fff);

  /* Body/Large/Medium */
  font-family: Mazzard;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.PanoFormul__content__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.cursorPointer {
  cursor: pointer;
}
.w260 {
  width: 260px !important;
}
.PanoFormul__content__header__right {
  display: flex;
  flex-direction: row;
  gap: 16px;
  align-items: center;
}
.formulSiralama {
  display: flex;
  padding: 8px 16px;
  justify-content: center;
  align-items: center;
  gap: 10px;
}
.formulSiralama .btn {
  height: auto !important;
  padding: 8px 16px !important;
  border-radius: 16px;
  border: 1px solid var(--Dark-Mode-Element-Divider, rgba(255, 255, 255, 0));
  background: var(--Dark-Mode-Card-Light, #35353a);
  color: var(--Dark-Mode-Text-1, #fff);
}
.formulSiralama .btn:hover,
.formulSiralama.show > .btn-success.dropdown-toggle {
  height: auto !important;
  padding: 8px 16px !important;
  border-radius: 16px;
  border: 1px solid var(--Dark-Mode-Element-Divider, rgba(255, 255, 255, 0));
  background: var(--Dark-Mode-Card-Light, #2b2b2e);
}
.formulSiralama .dropdown-menu {
  padding: 12px 0px !important;
  border-radius: 0px 0px 24px 24px !important;
  background: var(--Dark-Mode-Card-Light, #35353a) !important;
  box-shadow: 0px 13px 22.4px 0px rgba(0, 0, 0, 0.25) !important;
}
.formulSiralama .dropdown-item {
  padding: 24px !important;
  color: var(--Dark-Mode-Text-1, #fff);

  /* Body/XLarge/Semibold */
  font-family: Mazzard;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.formulSiralama .dropdown-item:hover {
  background-color: #2b2b2e !important;
  color: var(--Dark-Mode-Text-1, #fff) !important;
}
.formulSiralama.btn-check:active + .formulSiralama.btn-success:focus,
.formulSiralama.btn-check:checked + .formulSiralama.btn-success:focus,
.formulSiralama.btn-success.active:focus,
.formulSiralama.btn-success:active:focus,
.formulSiralama.show > .btn-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(153, 156, 154, 0.5);
}
@media screen and (max-width: 1000px) {
  .PanoFormul {
    padding: 0 !important;
    margin: 0 !important;
  }
  .PanoFormul__container {
    padding: 10px;
  }
  .PanoFormul__content__header {
    flex-direction: column;
  }
  .formulSiralama .btn {
    height: auto !important;
    padding: 8px !important;
    border-radius: 16px;
    font-size: 12px;
  }
  .PanoFormul__content__header__right .modalSearch__input {
    width: auto !important;
    padding: 8px !important;
    font-size: 12px;
  }
  .PanoFormul__content__item {
    width: 48%;
    height: 220px;
  }
  .PanoFormul__content__item__bottom {
    padding: 12px 6px;
    height: 145px;
    border-radius: 16px;
  }
  .PanoFormul__content__item__bottom .title {
    font-size: 14px;
  }
  .PanoFormul__content__item__bottom .whois {
    font-size: 12px;
  }
  .PanoFormul__content__item__bottom .puan span {
    font-size: 10px;
  }
  .PanoFormul__content__item__bottom__bottom__text {
    width: auto;
    font-size: 8px;
  }
  .PanoFormul__content__item__bottom__bottom__text span {
    text-wrap: nowrap;
  }
  .PanoFormul h3 {
    font-size: 24px;
  }
}
