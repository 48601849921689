.ProfilBody__container {
  display: flex;
  width: 100%;
  padding-top: 16px;
  padding-bottom: 40px;
}
.ProfilBody__puans {
  display: flex;
  width: 100%;
  padding: 16px 0;
  justify-content: space-between;
  align-content: flex-start;
  gap: 16px;
  flex-wrap: wrap;
}
.ProfilBody__OverviewCard {
  display: flex;
  width: 15.33%;
  height: 146px;
  padding: 16px;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  flex-shrink: 0;
}
.bgOverCard1 {
  border-radius: 32px;
  border: 4px solid rgba(20, 189, 147, 0.41);
  background: var(
    --Dark-Mode-Gradients-Memnun-Grad,
    linear-gradient(180deg, #32e5b8 0%, #15ba91 100%)
  );
  box-shadow: 0px 0px 0px 8px rgba(255, 255, 255, 0.3) inset;
}
.bgOverCard2 {
  border-radius: 32px;
  border: 4px solid #9a7bff;
  background: var(
    --Dark-Mode-Gradients-Sirius-Grad,
    linear-gradient(180deg, #baa5ff 0%, #997bfd 100%)
  );
  box-shadow: 0px 0px 0px 8px rgba(255, 255, 255, 0.3) inset;
}
.bgOverCard3 {
  border-radius: 32px;
  border: 4px solid #f84141;
  background: var(
    --Dark-Mode-Gradients-Takipci-Grad,
    linear-gradient(180deg, #ff7575 0%, #f84141 100%)
  );
  box-shadow: 0px 0px 0px 8px rgba(255, 255, 255, 0.3) inset;
}
.bgOverCard4 {
  border-radius: 32px;
  border: 4px solid #00c2ff;
  background: var(
    --Dark-Mode-Gradients-Profil-Grad,
    linear-gradient(180deg, #56d7ff 0%, #00c2ff 100%)
  );
  box-shadow: 0px 0px 0px 8px rgba(255, 255, 255, 0.3) inset;
}
.bgOverCard5 {
  border-radius: 32px;
  border: 4px solid #ffae36;
  background: var(
    --Dark-Mode-Gradients-Extra-1,
    linear-gradient(180deg, #ffcb7e 0%, #ffae36 100%)
  );
  box-shadow: 0px 0px 0px 8px rgba(255, 255, 255, 0.3) inset;
}
.bgOverCard6 {
  border-radius: 32px;
  border: 4px solid #3ae4cf;
  background: var(
    --Dark-Mode-Gradients-Extra-2,
    linear-gradient(180deg, #3ae4cf 0%, #1dc4af 100%)
  );
  box-shadow: 0px 0px 0px 8px rgba(255, 255, 255, 0.3) inset;
}
.ProfilBody__OverviewCard__Top {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
  color: var(--Dark-Mode-Text-1, #fff);

  /* Body/XXLarge/Semibold */
  font-family: Mazzard;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.ProfilBody__OverviewCard__bottom {
  color: var(--Dark-Mode-Text-1, #fff);

  /* Heading/Heading 3 */
  font-family: Mazzard;
  font-size: 26px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
