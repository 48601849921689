.ListeFavori__container {
}
.ListeFavori__header {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}
.ListeFavori__header__left {
  display: flex;
  padding: 16px;
  justify-content: flex-start;
  align-items: center;
  width: 250px;
}
.ListeFavori__header__right {
  display: flex;
  padding: 16px;
  justify-content: flex-end;
  align-items: center;
  width: 250px;
}
.ListeFavori__header__tabs {
  display: inline-flex;
  padding: 4px;
  justify-content: center;
  align-items: center;
  border-radius: 99px;
  background: var(--Dark-Mode-Card-Dark, #1f1f21);
}
.ListeFavori__header__tab {
  color: var(--Dark-Mode-Text-4, #565659);

  display: flex;
  width: 146px;
  padding: 16px;
  flex-direction: column;
  align-items: center;

  /* Body/Large/Bold */
  font-family: Mazzard;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.ListeFavori__header__tab.active {
  border-radius: 48px;
  background: var(--Dark-Mode-Card-Light, #35353a);
  color: var(--Dark-Mode-Text-1, #fff);
}
.ListeFavori__header__body {
  display: flex;
  width: 100%;
  padding: 24px;
  flex-direction: column;
  align-items: center;
  gap: 16px;
}
.Listelerim__lists {
  display: flex;
  width: 100%;
  padding: 24px;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  border-radius: 24px;
  background: var(--Dark-Mode-Card-Dark, #1f1f21);
}
.OzelArama_lists_skelaton {
  width: 100%;
  gap: 16px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}
.OzelArama_lists_skelaton span,
.Listelerim__lists span,
.Sonuclar__body span {
  width: 100%;
}
.Listelerim__list_item {
  display: flex;
  min-height: 106px;
  padding: 16px 24px;
  justify-content: space-between;
  align-items: center;
  flex-shrink: 0;
  align-self: stretch;
  border-radius: 24px;
  border: 1px solid var(--Dark-Mode-Element-Divider, rgba(255, 255, 255, 0));
  background: var(--Dark-Mode-Card-Medium, #2b2b2e);
}
.Listelerim__list_item__left {
  display: flex;
  align-items: center;
  gap: 16px;
}
.Listelerim__list_item__left__title {
  display: flex;
  padding: 16px 24px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  flex: 1 0 0;
  border-radius: 16px;
  border: 1px solid var(--dark-mode-element-dvider-100, rgba(255, 255, 255, 0));
  background: rgba(154, 123, 255, 0.1);
  border-image: linear-gradient(
    90deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 1) 50%,
    rgba(255, 255, 255, 0) 100%
  );
}
.Listelerim__list_item__left__title__name {
  min-width: 200px;
  border-image-slice: 1;
  color: var(--Dark-Mode-Text-1, #fff);

  /* Body/XLarge/Semibold */
  font-family: Mazzard;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  white-space: nowrap;
  width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
}
.Listelerim__list_item__left__symbols {
  display: flex;
  padding: 12px 18px;
  align-items: center;
  gap: 8px;
  border-radius: 12px;
  border: 1px solid var(--dark-mode-element-dvider-100, rgba(255, 255, 255, 0));
  background: rgba(154, 123, 255, 0.1);
  border-image: linear-gradient(
    90deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 1) 50%,
    rgba(255, 255, 255, 0) 100%
  );
  border-image-slice: 1;
  color: var(--Dark-Mode-Text-1, #fff);

  /* Body/Large/Medium */
  font-family: Mazzard;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  flex-wrap: wrap;
}
.Listelerim__list_item__right {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 24px;
}
.Listelerim__list_item__right__detail {
  display: flex;
  width: 165px;
  padding: 16px 24px;
  align-items: center;
  gap: 8px;
  border-radius: 24px;
  border: 1px solid var(--Dark-Mode-Text-1, #fff);
  color: var(--Dark-Mode-Text-1, #fff);

  /* Body/Normal/Medium */
  font-family: Mazzard;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.Listelerim__container {
  position: relative;
}
.Listelerim__add {
  position: absolute;
  right: 0;
  width: 200px !important;
  top: -75px;
  height: 51px !important;
}
.Listelerim__row {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
}
.Listelerim__back {
  color: var(--Dark-Mode-Text-1, #fff);

  /* Heading/Heading 5 */
  font-family: Mazzard;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.Listelerim__top {
  display: flex;
  width: 100%;
  height: 106px;
  padding: 16px;
  justify-content: space-between;
  align-items: center;
  border-radius: 24px;
  background: var(--Dark-Mode-Card-Medium, #2b2b2e);
}
.Listelerim__top__title {
  display: flex;
  padding: 16px;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  border-radius: 16px;
  border: 1px solid var(--Dark-Mode-Text-2, #9a9a9a);
  background: var(--Dark-Mode-Card-Selected, #565659);
}
.Listelerim__top__title__subname {
  color: var(--Dark-Mode-Text-2, #9a9a9a);

  /* Body/Normal/Regular */
  font-family: Mazzard;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.Listelerim__top__title__name {
  color: var(--Dark-Mode-Text-1, #fff);

  /* Body/XLarge/Semibold */
  font-family: Mazzard;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  width: 300px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.Listelerim__button {
  display: flex;
  padding: 16px 24px;
  align-items: center;
  gap: 8px;
  border-radius: 24px;
  border: 1px solid var(--Brand-Main-Color, #3ab22f);
  color: var(--Brand-Main-Color, #3ab22f);

  /* Body/Normal/Medium */
  font-family: Mazzard;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.favorilerim__close {
  min-width: 24px;
  fill: var(--Dark-Mode-Text-1, #fff);
}
.wd-30 {
  width: 30%;
}
@media screen and (max-width: 1000px) {
  .ListeFavori__header__body,
  .Listelerim__lists {
    padding: 0 !important;
    margin: 0 !important;
    width: 100% !important;
  }
  .Listelerim__list_item {
    overflow-x: auto;
    height: auto;
    padding: 12px;
  }
  .Listelerim__list_item__left__title {
    font-size: 16px;
    padding: 16px;
  }
  .Listelerim__list_item__right__detail {
    padding: 8px;
    border-radius: 16px;
  }
  .Listelerim__list_item__left__symbols {
    font-size: 12px;
    padding: 8px;
  }
  .Listelerim__list_item__right {
    gap: 10px;
    margin-left: 10px;
  }
  .Listelerim__add {
    position: relative;
    right: 0;
    width: 100% !important;
    top: 0;
    height: 51px !important;
  }
  .Listelerim__list_item__left {
    flex-direction: column;
  }

  .wd-30 {
    width: 100%;
  }
}
