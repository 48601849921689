.Slider {
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: inline-flex;
  margin: 0 auto;
  text-align: center;
  margin-top: 54px;
}
.sliderTop {
  color: #c1c3c9;

  /* Heading/Heading 3 */
  font-family: Mazzard;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.sliderBottom {
  color: var(--Dark-Mode-Text-1, #fff);

  /* Heading/Heading 1 */
  font-family: Mazzard;
  font-size: 48px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-top: 16px;
  margin-bottom: 16px;
}
.sliderDesc {
  color: #8a92a2;
  width: 70%;
  color: "#8A92A2";
  font-family: Mazzard;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin: 0 auto;
}
.sliderImg {
  margin-top: 36px;
  display: block;
  position: relative;
}
.sliderAstronot {
  width: 244px;
  height: 365px;
  right: 0;
  position: absolute;
  bottom: 0;
}
@media (max-width: 1000px) {
  .Slider {
    margin-top: 20px;
  }
  .sliderImg {
    margin-top: 10px;
  }
  .sliderAstronot {
    width: 100px;
    height: 148px;
    right: 0;
    position: absolute;
    bottom: 0;
  }
  .sliderBottom {
    font-size: 30px;
  }
}
