* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: Arial, Helvetica, sans-serif;
}

:root {
  --logo-pattern: url("./assets/img/logo_pattern.png");
  --bs-btn-close-bg: "";
  --bg-profil: url("./assets/img/bg-profil.png");
  --background-color: #1a1c1e;
  --foreground-color: #567aff;
  --primary-text-color: #f6f6f6;
  --secondary-text-color: #0a0a0a;
  --pcolor: #b5b5b5;
  --pcolor2: #212529;
  --toggle-bg: #283452;
  --toggle-fg: #00a6ff;
  --Dark-Mode-Text-1: #fff;
  --Dark-Mode-Text-2: #fff;
  --Dark-Mode-Text-3: #6c6c6c;
  --Dark-Mode-Text-4: #b1b1b1;
  --Dark-Mode-Input-Label: #b1b1b1;
  --Dark-Mode-Input-Text: #fff;
  --Dark-Mode-Input-Placeholder: #b1b1b1;
  --Dark-Mode-svg: #fff;
  --Dark-Mode-Card-Medium: #2b2b2e;
  --Dark-Mode-Card-Light: #444447;
  --Dark-Mode-Card-Selected: #565659;
  --Dark-Mode-Card-Dark: #1f1f21;
  --Dark-Mode-Card-Dark2: #2b2b2e;
  --Dark-Mode-Element-Divider: rgba(255, 255, 255, 0);
  --Dark-Mode-close: #0000;
  --Dark-Mode-Gradients-Card-bgGrad: linear-gradient(
    180deg,
    rgba(43, 43, 46, 0.8) 0%,
    rgba(34, 36, 38, 0.32) 100%
  );
  --bs-table-border-color: #2b2b2e;
  --bs-table-border-color2: #f1f1f1;
}

[data-theme="light"] {
  --logo-pattern: url("./assets/img/logo_pattern_light.png");
  --bs-btn-close-bg: "";
  --bg-profil: "";
  --pcolor: #212529;
  --pcolor2: #f2f2f2;
  --background-color: #fff;
  --foreground-color: #003cff;
  --primary-text-color: #0a0a0a;
  --secondary-text-color: #ffffff;
  --toggle-bg: #f0eb9d;
  --toggle-fg: #ffd000;
  --Dark-Mode-Text-1: #0a0a0a;
  --Dark-Mode-Text-2: #474747;
  --Dark-Mode-Text-3: #6c6c6c;
  --Dark-Mode-Text-4: #909090;
  --Dark-Mode-Input-Label: #909090;
  --Dark-Mode-Input-Text: #000;
  --Dark-Mode-Input-Placeholder: #909090;
  --Dark-Mode-svg: #0a0a0a;
  --Dark-Mode-Card-Medium: #f1f1f1;
  --Dark-Mode-Card-Light: #e7e7e7;
  --Dark-Mode-Card-Selected: #fafafa;
  --Dark-Mode-Card-Dark: #fafafa;
  --Dark-Mode-Card-Dark2: #dadada;
  --Dark-Mode-Element-Divider: rgba(255, 255, 255, 0);
  --Dark-Mode-close: #8e8c8c75;
  --Dark-Mode-Gradients-Card-bgGrad: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0.8) 0%,
    rgba(230, 230, 230, 0.32) 100%
  );

  --bs-table-border-color: #f1f1f1;
  --bs-table-border-color2: #2b2b2e;
}
