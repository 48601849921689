.Bildirimler__container__title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: var(--Dark-Mode-Text-1, #fff);
  padding: 24px 0;

  /* Heading/Heading 3 */
  font-family: Mazzard;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.Bildirimler__container__content {
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  gap: 32px;
  width: 100%;
}
.Bildirimler__container__content__itemx {
  width: 100%;
  border-radius: 16px;
  padding: 16px;
  border: 1px solid var(--bs-table-border-color2);
  background: var(--Dark-Mode-Card-Medium, #2b2b2e);
}
.Bildirimler__container__content__item {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 16px;
}
.Bildirimler__container__content__skelaton {
  width: 100%;
  gap: 16px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}
.Bildirimler__container__content__skelaton span {
  height: 60px;
  width: 100%;
  border-radius: 16px;
  padding: 16px;
}
.Bildirimler__container__content__item_content {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 8px;
}
.Bildirimler__container__content__item_date {
  color: var(--Dark-Mode-Text-1, #fff);
  font-size: 8px;
}
.Bildirimler__container__content__item_text {
  color: var(--Dark-Mode-Text-1, #fff);

  /* Body/Large/Regular */
  font-family: Mazzard;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.Bildirimler__container__content__item_formul {
  display: flex;
  max-width: 350px;
  height: 48px;
  padding: 16px 32px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 24px;
  border: 2px solid rgba(255, 174, 54, 0.78);
  background: linear-gradient(
    180deg,
    rgba(255, 174, 54, 0.62) 0%,
    rgba(255, 174, 54, 0.17) 100%
  );
}
.Bildirimler__container__content__itemz {
  padding-top: 16px;
  margin-top: 16px;
  border-top: 1px solid rgba(255, 174, 54, 0.78);
}
