.PariteOdasi__top {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
}
.PariteOdasi__top h1 {
  color: var(--Dark-Mode-Text-1, #fff);

  /* Heading/Heading 3 */
  font-family: Mazzard;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;

  margin: 0;
  padding: 0;
}
.PariteOdasi__top button {
  display: flex;
  width: 196px !important;
  height: 56px;
  padding: 8px 32px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  flex-shrink: 0;
}

.PariteOdasi__secim,
.PariteOdasi__secim__1,
.PariteOdasi__secim__2 {
  display: flex;
  align-items: center;
  gap: 24px;
}
.PariteOdasi_secim_text {
  color: var(--Dark-Mode-Text-1, #fff);

  /* Body/XLarge/Semibold */
  font-family: Mazzard;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.PariteOdasi__select {
  display: flex !important;
  width: 150px !important;
  padding: 16px !important;
  justify-content: center;
  align-items: center;
  gap: 16px !important;
  border-radius: 16px !important;
  border: 1px solid var(--dark-mode-element-dvider-100, rgba(255, 255, 255, 0)) !important;
  background: var(--Dark-Mode-Card-Medium, #2b2b2e) !important;
  width: 100%;
  border-image: linear-gradient(
    90deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 1) 50%,
    rgba(255, 255, 255, 0) 100%
  ) !important;
  border-image-slice: 1 !important;
}
.form-select-override {
  padding: 0.5rem 0.5rem 0.75rem 1.5rem !important;
}
.PariteOdasi__select option {
  color: var(--Dark-Mode-Text-1, #fff);

  /* Body/Large/Semibold */
  font-family: Mazzard;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-align: center;
}
.PariteOdasi_text p {
  color: var(--Dark-Mode-Text-2, #9a9a9a);

  /* Body/Large/Regular */
  font-family: Mazzard;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.PariteOdasi_text p a {
  color: var(--Dark-Mode-Text-2, #9a9a9a);

  /* Body/Large/Regular */
  font-family: Mazzard;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-decoration-line: underline;
}
.PariteOdasi__sonuc {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 132px;
}
.PariteOdasi__sonucyok {
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  gap: 40px;
}
.PariteOdasi__sonucyok svg {
  margin-left: 50px;
}
.PariteOdasi__sonucyok__text {
  color: var(--Dark-Mode-Text-1, #fff);
  text-align: center;

  /* Body/XLarge/Regular */
  font-family: Mazzard;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.PariteOdasi__sonucyok__text span {
  color: var(--Dark-Mode-Text-1, #fff);

  /* Body/XXLarge/Semibold */
  font-family: Mazzard;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.PariteOdasi__bottom {
  display: flex;
  padding: 16px 32px;
  justify-content: center;
  align-items: center;
  gap: 48px;
  align-self: stretch;
  border-radius: 24px;
  background: var(--Dark-Mode-Card-Medium, #2b2b2e);
  margin-bottom: 24px;
}
.PariteOdasi__bottom span {
  color: var(--Dark-Mode-Text-2, #9a9a9a);

  /* Body/Large/Regular */
  font-family: Mazzard;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  cursor: pointer;
}
.PariteOdasi__bottom__button:hover span {
  color: #00e01e;
}
.PariteOdasi__bottom__button {
  position: relative !important;
}
.PariteOdasi__bottom__button__close {
  position: absolute !important;
  right: -14px !important;
  top: -14px !important;
  border-radius: 45px !important;
  background: var(--Dark-Mode-Card-Medium, #2b2b2e) !important;
}
.PariteOdasi__bottom__button__close svg {
  fill: var(--Dark-Mode-svg, #2b2b2e) !important;
}
.PariteOdasi__bottom__button__close:hover svg {
  fill: #ec4d03 !important;
}
.PariteOdasi__sonuc1 {
  width: 100%;
  display: flex;
  flex-direction: column;
}
@media screen and (max-width: 1000px) {
  .PariteOdasi__top h1 {
    font-size: 24px;
  }
  .PariteOdasi__top {
    flex-direction: column;
    justify-items: center;
    justify-content: center;
    gap: 13px;
    text-align: center;
    text-wrap: nowrap;
  }
  .PariteOdasi__top button {
    display: flex;
    width: 100% !important;
    height: 56px;
  }
  .PariteOdasi__secim,
  .PariteOdasi__secim__1,
  .PariteOdasi__secim__2 {
    gap: 8px;
  }
  .PariteOdasi_secim_text {
    font-size: 14px;
  }
  .PariteOdasi__select {
    width: 120px !important;
    font-size: 14px;
  }
  .PariteOdasi__bottom {
    gap: 18px;
    justify-content: flex-start;
    overflow-x: auto;
  }
}
