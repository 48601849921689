.HomePage {
  background-image: url("../../../assets/img/landing_bg.png");
  background-repeat: no-repeat;
  background-position: center top;
}
.HomeMenu {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  padding-top: 24px;
  gap: 32px;
  justify-content: space-evenly;
  margin: 0 auto;
}

.HomeMenu__logo {
  width: 157.379px;
  height: 56px;
  flex-shrink: 0;
  padding-right: 32px;
}

.HomeMenu__logo__img {
}

.HomeMenu__list {
  border-radius: 69px;
  padding: 8px 24px 8px 8px;
  background: linear-gradient(
    180deg,
    rgba(51, 51, 54, 0.51) 0%,
    rgba(43, 43, 46, 0.22) 100%
  );

  backdrop-filter: blur(2px);
}

.HomeMenu__list__ul {
  display: flex;
  align-items: center;
}
.HomeMenu__list__ul li {
  display: inline-block;
  padding: 4px;
  align-items: center;
  gap: 5px;
  border-radius: 32px !important;
  margin: 0 !important;
}
.HomeMenu__list__ul li a {
  color: #fff !important;
  /* Body/XLarge/Regular */
  font-family: Mazzard;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin: 0 !important;
  padding: 2px 6px !important;
}

.HomeMenu__list__active {
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0.15) 0%,
    rgba(255, 255, 255, 0.03) 100%
  );
  backdrop-filter: blur(2px);
}
.HomeMenu__right {
  display: flex;
  align-items: center;
  gap: 24px;

  right: 0;
  position: absolute;
}
.HomeMenu__social {
  display: flex;
  align-items: center;
  gap: 24px;
}
.HomeMenu__social_img {
  width: 24px;
  height: 24px;
  fill: #797979;
}
.HomeMenu__social_img:hover {
  cursor: pointer;
  fill: #fff;
}
.HomeMenu__button {
  display: flex;
  width: 196px;
  height: 56px;
  padding: 8px 32px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 86px;
  background: linear-gradient(180deg, #3ab22f 0%, #237f1a 100%);
  color: var(--Neutral-White, #fff);

  /* Body/Large/Semibold */
  font-family: Mazzard;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.navbar-light .navbar-toggler.collapsed .navbar-toggler-icon {
  background-image: url("../../../assets/img/icon/menu.png");
}
.navbar {
  width: 100%;
}
@media screen and (max-width: 768px) {
  .navbar-light .navbar-toggler {
    z-index: 101;
  }
  .navbar-collapse {
    position: absolute;
    right: 0;
    left: auto;
    top: -24px;
    bottom: 0;
    z-index: 100;
    border-radius: 16px;
    background: linear-gradient(
      180deg,
      rgba(51, 51, 54, 0.51) 0%,
      rgba(43, 43, 46, 0.22) 100%
    );
    backdrop-filter: blur(2px);
    height: 100vh;
    width: 261px;
    padding-top: 24px;

    padding: 48px 24px;
    display: flex;
    flex-shrink: 0;
  }
  .HomeMenu__list {
    background: #0000;
    backdrop-filter: none;
    padding: 0;
  }
  .HomeMenu__right {
    flex-direction: column;
    bottom: 108px;
    right: 10%;
  }
  .navbar-light .navbar-toggler .navbar-toggler-icon {
    background-image: url("../../../assets/img/icon/close.png");
  }
}
