.aboutContainer {
  width: 100%;
  height: 100%;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 24px;
  display: inline-flex;
}
.HomeAbout__bg {
}
.aboutTitle {
  color: white;
  font-size: 48px;
  font-family: Mazzard;
  font-weight: 700;
  word-wrap: break-word;
}
.aboutBody {
  padding: 24px;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 88px;
  display: inline-flex;
}
.about_left {
  flex: 1 1 0;
  color: #dddddd;
  font-size: 24px;
  font-family: Mazzard;
  font-weight: 400;
  word-wrap: break-word;
}
.about_right {
  flex: 1 1 0;
  color: #dddddd;
  font-size: 24px;
  font-family: Mazzard;
  font-weight: 400;
  word-wrap: break-word;
}
@media screen and (max-width: 1000px) {
  .aboutTitle {
    font-size: 32px;
  }
  .about_left,
  .about_right {
    font-size: 16px;
  }
}
