.Packages {
  background-image: url("../..//assets/img/paket-background.png") !important;
  background-repeat: no-repeat !important;
  background-size: cover !important;
}
.Packages_container {
  background-image: url("../../assets/img/paket-bottom.png") !important;
  background-repeat: no-repeat !important;
  background-size: 100% !important;
  background-position: bottom;
  padding-bottom: 150px;
}

.Paketler__row {
  display: flex;
  width: 100%;
  height: calc(100vh - 150px);
  padding: 0px 24px;
  justify-content: space-between;
  align-items: center;
}
.Paketler__col__left {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 16px;
}
.Paketler__col__left__title {
  color: var(--Dark-Mode-Text-2, #9a9a9a);

  /* Heading/Heading 3 */
  font-family: Mazzard;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.Paketler__col__left__slogan {
  color: var(--Dark-Mode-Text-1, #fff);

  /* Heading/Heading 1 */
  font-family: Mazzard;
  font-size: 48px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.Paketler__col__left__desc {
  color: var(--Dark-Mode-Text-3, #797979);

  /* Body/XXLarge/Regular */
  font-family: Mazzard;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.Paketler__col__right {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  width: 688px;
}
.Paketler__col__right__top {
  display: flex;
  width: 100%;
  padding: 34px 32px;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 32px;
  border-radius: 32px;
  border: 3px solid var(--Dark-Mode-Input-Border, #35353a);
  background: var(--Dark-Mode-Card-Dark, #1f1f21);
}
.Paketler__col__right__body {
  display: flex;
  width: 100%;
  padding: 34px 32px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 32px;
  border-radius: 32px;
  border: 3px solid var(--Dark-Mode-Input-Border, #35353a);
  background: var(--Dark-Mode-Card-Dark, #1f1f21);
}
.Paketler__col__right__top__left,
.Paketler__col__right__top__right {
  opacity: 0.3;
  cursor: pointer;
}
.Paketler__col__right__top__left.active,
.Paketler__col__right__top__right.active {
  opacity: 1;
}
.Paketler__col__right__body__title {
  color: var(--Dark-Mode-Text-1, #fff);

  /* Body/Large/Regular */
  font-family: Mazzard;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.Paketler__col__right__body__packages {
  display: flex;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
  flex-direction: row;
}
.Paketler__col__right__body__package,
.Paketler__col__right__body__packageplus {
  display: flex;
  padding: 12px 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  flex: 1 0 0;
  border-radius: 16px;
  background: var(--Dark-Mode-Card-Medium, #2b2b2e);
}
.Paketler__col__right__body__package.active {
  border-radius: 16px;
  border-top: 1px solid var(--Colors-Aqua, #00c2ff);
  border-bottom: 1px solid var(--Colors-Aqua, #00c2ff);
  background: rgba(0, 194, 255, 0.2);
  color: var(--Colors-Aqua, #00c2ff);
}
.Paketler__col__right__body__packageplus.active {
  border-radius: 16px;
  border-top: 1px solid var(--Colors-Heliotrope, #9a7bff);
  border-bottom: 1px solid var(--Colors-Heliotrope, #9a7bff);
  background: rgba(139, 111, 230, 0.2);

  /* Button/Primary Hover */
  box-shadow: 0px 0px 0px 2px rgba(0, 0, 0, 0) inset,
    0px 0px 56.9px 0px rgba(0, 0, 0, 0.25) inset;
}
.Paketler__col__right__body__desc {
  display: flex;
  padding: 16px;
  justify-content: center;
  align-items: center;
  gap: 32px;
  align-self: stretch;
  border-radius: 16px;
  background: var(--Dark-Mode-Card-Medium, #2b2b2e);
}
ul.Paketler__col__right__body__desc__list {
  columns: 2;
  -webkit-columns: 2;
  -moz-columns: 2;
  margin-bottom: 0;
}
ul.Paketler__col__right__body__desc__list li {
  color: var(--Dark-Mode-Text-1, #fff);

  /* Body/Normal/Regular */
  font-family: Mazzard;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding-bottom: 8px;
}
.Paketler__col__right__body__fiyat {
  display: flex;
  padding: 24px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 12px;
  align-self: stretch;
  border-radius: 24px;
  border: 1px solid rgba(0, 194, 255, 0.43);
  background: var(
    --Dark-Mode-Gradients-Profil-Grad,
    linear-gradient(180deg, #56d7ff 0%, #00c2ff 100%)
  );
}
.Paketler__col__right__body__fiyat__desc {
  display: flex;
  padding: 8px 16px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 4px;
  align-self: stretch;
  border-radius: 16px;
  background: linear-gradient(180deg, #00c2ff 0%, #008799 100%);
  box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.05),
    0px 10px 10px 0px rgba(0, 0, 0, 0.04), 0px 22px 13px 0px rgba(0, 0, 0, 0.03),
    0px 38px 15px 0px rgba(0, 0, 0, 0.01), 0px 60px 17px 0px rgba(0, 0, 0, 0);
}
.Paketler__col__right__body__fiyat .satinalButton {
  width: 100% !important;
}
.Paketler__col__right__body__fiyat .satinalButton Button {
  display: flex;
  padding: 8px 16px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 4px;
  align-self: stretch;
  border-radius: 16px;
  border: 2px solid rgba(123, 251, 255, 0.78) !important;
  background: linear-gradient(180deg, #00c2ff 0%, #008799 100%) !important;
  box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.05),
    0px 10px 10px 0px rgba(0, 0, 0, 0.04), 0px 22px 13px 0px rgba(0, 0, 0, 0.03),
    0px 38px 15px 0px rgba(0, 0, 0, 0.01), 0px 60px 17px 0px rgba(0, 0, 0, 0) !important;
}
.Paketler__col__right__body__fiyatplus {
  display: flex;
  padding: 24px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 12px;
  align-self: stretch;
  border-radius: 24px;
  border: 1px solid rgba(154, 123, 255, 0.43);
  background: var(
    --Dark-Mode-Gradients-Sirius-Grad,
    linear-gradient(180deg, #baa5ff 0%, #997bfd 100%)
  );
}
.Paketler__col__right__body__fiyatplus__desc {
  display: flex;
  padding: 8px 16px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 4px;
  align-self: stretch;
  border-radius: 16px;
  background: linear-gradient(180deg, #9a7bff 0%, #704be8 100%);
  box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.05),
    0px 10px 10px 0px rgba(0, 0, 0, 0.04), 0px 22px 13px 0px rgba(0, 0, 0, 0.03),
    0px 38px 15px 0px rgba(0, 0, 0, 0.01), 0px 60px 17px 0px rgba(0, 0, 0, 0);
}
.Paketler__col__right__body__fiyatplus .satinalButton {
  width: 100% !important;
}
.Paketler__col__right__body__fiyatplus .satinalButton Button {
  display: flex;
  padding: 8px 16px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 4px;
  align-self: stretch;
  border-radius: 16px;
  border: 2px solid rgba(154, 123, 255, 0.78) !important;
  background: linear-gradient(180deg, #9a7bff 0%, #704be8 100%) !important;
  box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.05),
    0px 10px 10px 0px rgba(0, 0, 0, 0.04), 0px 22px 13px 0px rgba(0, 0, 0, 0.03),
    0px 38px 15px 0px rgba(0, 0, 0, 0.01), 0px 60px 17px 0px rgba(0, 0, 0, 0) !important;
}
.Paketler__col__right__body__fiyat__desc__title {
  color: var(--Neutral-White, #fff);

  /* Body/Large/Semibold */
  font-family: Mazzard;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.Paketler__col__right__body__fiyat__desc__price {
  display: flex;
  align-items: flex-start;
  gap: 4px;
}
.Paketler__col__right__body__fiyat__desc__price .toplam:before {
  content: " ";
  display: block;
  width: 100%;
  border-top: 2px solid #35353a;
  height: 12px;
  position: absolute;
  bottom: 0;
  left: 0;
  transform: rotate(340deg);
}
.Paketler__col__right__body__fiyat__desc__price .toplam {
  color: var(--Neutral-Gray-7, #35353a);

  position: relative;
  font-family: Mazzard;
  font-size: 15px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.3px;
}
.Paketler__col__right__body__fiyat__desc__price .net {
  color: var(--Dark-Mode-Text-1, #fff);
  font-size: 32px;
  font-family: Mazzard;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.64px;
}
.Paketler__col__right__body__fiyat__desc__price .kdv {
  color: var(--Neutral-White, #fff);

  font-family: Mazzard;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.32px;
}
.Paketler__col__right__body__satinal {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 24px;
}
.Paketler__col__right__body__satinal__title {
  color: var(--Dark-Mode-Text-1, #fff);

  /* Body/Large/Regular */
  font-family: Mazzard;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.Paketler__col__right__body__satinal__button {
  display: flex;
  width: 196px;
  height: 56px;
  padding: 8px 32px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 86px;
  background: var(--Colors-Aqua, #00c2ff);
  color: var(--Neutral-Gray-10, #1a1c1e);

  /* Body/Large/Semibold */
  font-family: Mazzard;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.Paketler__col__right__body__satinalplus__button {
  display: flex;
  width: 196px;
  height: 56px;
  padding: 8px 32px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 86px;
  background: var(--Colors-Heliotrope, #9a7bff);
  color: var(--Neutral-Gray-10, #1a1c1e);

  /* Body/Large/Semibold */
  font-family: Mazzard;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.Paketler__odeme_top {
  display: flex;
  padding: 24px 0;
  justify-content: center;
  align-items: center;
  gap: 24px;
}
.Paketler__odeme_top__col {
  display: flex;
  width: 180px;
  padding: 16px;
  flex-direction: column;
  align-items: center;
  color: var(--Dark-Mode-Text-4, #565659);

  /* Body/Large/Medium */
  font-family: Mazzard;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.Paketler__odeme_top__col.active {
  border-radius: 48px;
  background: var(--Dark-Mode-Card-Light, #35353a);
  color: var(--Dark-Mode-Text-1, #fff);
}
.Paketler__odeme_bottom {
  display: flex;
  width: 100%;
  padding: 36px;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  border-radius: 32px;
  background: var(--Dark-Mode-Card-Dark, #1f1f21);
}
.Paketler__odeme_bottom__left {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
}
.Paketler__odeme_bottom__right {
  display: flex;
  padding: 24px;
  flex-direction: column;
  align-items: center;
  gap: 71px;
  width: 53% !important;
}
.Paketler__odeme_bottom__kredikarti {
  display: inline-block;
  width: 385px;
  height: 245px;
  -webkit-transform: rotate(0deg);
  -webkit-transform: rotate(0deg);
  -ms-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: all 0.5s ease-out;
  -webkit-transition: all 0.5s ease-out;
  transition: all 0.5s ease-out;
}
.Paketler__odeme_bottom__kredikarti__front {
  position: relative;
}

.Paketler__odeme_bottom__kredikarti__back {
  position: relative;
  display: none;
}
.Paketler__odeme_bottom__kredikarti__desc {
  position: absolute;
  bottom: 0px;
  padding: 30px;
  width: 100%;
}
.Paketler__odeme_bottom__kredikarti__info {
  display: flex;
  flex-direction: row;
  gap: 20px;
}
.Paketler__odeme_bottom__kredikarti__number {
  color: var(--Dark-Mode-Text-4, #fff);

  /* Body/Large/Medium */
  font-family: Mazzard;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  padding-bottom: 20px;
}
.Paketler__odeme_bottom__kredikarti__name {
  color: var(--Dark-Mode-Text-4, #fff);

  /* Body/Large/Medium */
  font-family: Mazzard;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.Paketler__odeme_bottom__kredikarti__date {
  color: var(--Dark-Mode-Text-4, #fff);

  /* Body/Large/Medium */
  font-family: Mazzard;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.Paketler__odeme_bottom__kredikarti__cvc {
  color: var(--Dark-Mode-Text-4, #fff);

  /* Body/Large/Medium */
  font-family: Mazzard;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  bottom: 114px;
  display: flex;
  position: absolute;
  right: 130px;
}
.Paketler__odeme_bottom__kredikarti.flipped {
  -webkit-transform: rotateY(180deg);
  -webkit-transform: rotateY(180deg);
  -ms-transform: rotateY(180deg);
  transform: rotateY(180deg);
}
.Paketler__odeme_bottom__kredikarti.flipped
  .Paketler__odeme_bottom__kredikarti__front {
  display: none;
}
.Paketler__odeme_bottom__kredikarti.flipped
  .Paketler__odeme_bottom__kredikarti__back {
  display: block;
  -webkit-transform: rotateY(180deg);
  -webkit-transform: rotateY(180deg);
  -ms-transform: rotateY(180deg);
  transform: rotateY(180deg);
}
.Paketler__odeme_bottom__kredikarti__form {
  width: 100%;
}
.Paketler__odeme_bottom__kredikarti__form form {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
}
.Paketler__odeme_bottom__kredikarti__form label {
  color: var(--Dark-Mode-Input-Label, #fff);

  /* Body/Normal/Medium */
  font-family: Mazzard;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.Paketler__odeme_bottom__kredikarti__form input {
  display: flex;
  height: 56px;
  padding: 16px;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  border-radius: 16px !important;
  border: 1px solid var(--Dark-Mode-Input-Border, #35353a) !important;
  background: var(--Dark-Mode-Body-bgColor, #1a1c1e) !important;
}
.Paketler__odeme_bottom__kredikarti__form .colform {
  width: 100%;
}
.gap24 {
  gap: 24px;
}
.Paketler__odeme_bottom__kredikarti__form .paketSatinAl {
  display: flex !important;
  height: 56px !important;
  padding: 8px 32px !important;
  justify-content: center !important;
  align-items: center !important;
  gap: 8px !important;
  align-self: stretch !important;
}
.Paketler__odeme_bottom__right_taksitler {
  display: flex;
  width: 100%;
  min-height: 635px;
  padding: 24px;
  flex-direction: column;
  align-items: center;
  gap: 21px;
}
.Paketler__odeme_bottom__right_taksitler h2 {
  color: var(--Dark-Mode-Text-1, #fff);

  /* Heading/Heading 3 */
  font-family: Mazzard;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.Paketler__odeme_bottom__right_taksitler p {
  color: var(--Dark-Mode-Text-1, #fff);
  text-align: center;

  /* Body/Large/Regular */
  font-family: Mazzard;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.Paketler__odeme_bottom__disclaimer {
  display: flex;
  padding: 16px;
  align-items: center;
  gap: 16px;
  align-self: stretch;
  border-radius: 16px;
  background: var(--Dark-Mode-Card-Medium, #f28d63);
}
.Paketler__odeme_bottom__disclaimer p {
  color: var(--Dark-Mode-Text-1, #fff);

  /* Body/Large/Regular */
  font-family: Mazzard;
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px;
  margin-bottom: 0 !important;
}
.Paketler__odeme_bottom__table {
  width: 100%;
  height: 100%;
}

.Paketler__odeme_bottom__table_top {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 16px;
  color: var(--Dark-Mode-Text-1, #fff);
}
.Paketler__odeme_bottom__table_body {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.Paketler__odeme_bottom__table_body_row {
  display: flex;
  flex-direction: row;
  gap: 14px;
}
.Paketler__odeme_bottom__table_body_row div:first-child {
  display: flex;
  max-width: 132px;
  align-items: flex-start;
  gap: 44px;
}
.Paketler__odeme_bottom__table_body_row div {
  display: flex;
  height: 56px;
  padding: 16px;
  justify-content: space-between;
  align-items: center;
  flex: 1 0 0;
  border-radius: 16px;
  border: 1px solid var(--Dark-Mode-Card-Lightest, #444449);
  background-color: var(--Dark-Mode-Card-Medium, #2b2b2e);
  color: var(--Dark-Mode-Text-1, #fff);

  /* Body/Large/Medium */
  font-family: Mazzard;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.Paketler__odeme_bottom__table_footer {
  display: flex;
  padding: 12px 16px;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  color: var(--Dark-Mode-Text-1, #fff);

  /* Body/Large/Regular */
  font-family: Mazzard;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.Paketler__odeme_bottom__kredikarti__logo {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 24px;
  color: var(--Dark-Mode-Text-1, #fff);
  font-family: Mazzard;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.odemeTablo {
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;
}
.odemeTabloHead {
  display: flex;
  width: 100%;
  padding: 16px;
  gap: 20px;
  justify-content: flex-start;
  align-items: center;
  border-radius: 16px;
  background: var(--Dark-Mode-Card-Medium, #2b2b2e);
}
.odemeTabloHeadText {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.odemeTabloBody {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
}
.odemeTabloRow {
  display: flex;
  align-items: flex-start;
  gap: 16px;
  padding: 8px 0;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  border-bottom: 1px solid
    var(--dark-mode-element-dvider-100, rgba(255, 255, 255, 0));
  border-image: linear-gradient(
    90deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 1) 50%,
    rgba(255, 255, 255, 0) 100%
  );
  border-image-slice: 1;
}
.odemeTabloRowText {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.Paketler__Premiumplus__desc {
  display: flex;
  position: relative;
  width: 100%;
  height: 48px;
  padding: 16px 32px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 24px;
  border: 2px solid rgba(154, 123, 255, 0.78);
  background: linear-gradient(
    180deg,
    rgba(154, 123, 255, 0.62) 0%,
    rgba(154, 123, 255, 0.17) 100%
  );
}
.Paketler__Premiumplus__desc span {
  color: var(--Dark-Mode-Text-1, #fff);

  /* Body/Large/Medium */
  font-family: Mazzard;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.Paketler__Premium__desc {
  display: flex;
  position: relative;
  width: 100%;
  height: 48px;
  padding: 16px 32px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 24px;
  border: 2px solid rgba(255, 123, 123, 0.78);
  background: linear-gradient(
    180deg,
    rgba(255, 123, 123, 0.62) 0%,
    rgba(255, 123, 123, 0.17) 100%
  );
}
.Paketler__Premium__desc span {
  color: var(--Dark-Mode-Text-1, #fff);

  /* Body/Large/Medium */
  font-family: Mazzard;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-decoration-line: line-through;
}
.Paketler__Premiumplus__desc__img {
  position: absolute;
  left: -13.5px;
  top: -15px;
}
@media screen and (max-width: 1000px) {
  .Packages_container {
    margin-bottom: 35px;
  }
  .Paketler__col__left {
    display: none;
  }
  .Packages_container .container-fluid,
  .Packages_container .container,
  .Paketler__row {
    padding: 0 !important;
    width: auto;
  }
  .Paketler__col__right__top {
    padding: 0px;
    gap: 6px;
    border: none;
  }
  .Paketler__col__right__body {
    padding: 12px;
    gap: 12px;
    border-radius: 12px;
  }
  .Paketler__col__right__body__package,
  .Paketler__col__right__body__packageplus {
    font-size: 14px;
  }
  .Paketler__col__right__body__satinal__title {
    font-size: 12px;
  }
  .Paketler__col__right__body__fiyat__title {
    font-size: 14px;
  }
  ul.Paketler__col__right__body__desc__list li {
    font-size: 12px;
  }
  .Paketler__col__right__body__fiyat__desc__price .net {
    font-size: 24px;
  }
  ul.Paketler__col__right__body__desc__list {
    padding-left: 0;
  }
  .Paketler__col__right__body__satinal__button {
    display: flex;
    width: 196px;
    height: 42px;
    padding: 8px 12px;
  }
  .Paketler__odeme_top {
    display: flex;
    padding: 0;
    justify-content: center;
    align-items: center;
    gap: 12px;
  }
  .Paketler__odeme_top__col {
    font-size: 14px;
  }
  .Paketler__odeme_bottom__disclaimer p {
    font-size: 12px;
    line-height: 16px;
  }
  .Paketler__odeme_bottom__right {
    width: 100% !important;
  }
  .Paketler__odeme_bottom__right_taksitler h2 {
    font-size: 18px;
  }
  .odemeTabloRowText {
    font-size: 12px;
  }
  .Paketler__Premiumplus__desc span {
    font-size: 14px;
  }
  .Paketler__odeme_bottom__table_body_row div:first-child {
    max-width: 100px;
  }
  .Paketler__odeme_bottom__table_body_row div {
    font-size: 14px;
    height: auto;
  }
  .Paketler__odeme_bottom__table_body_row svg {
    min-width: 16px;
    width: 16px;
    height: 16px;
  }
}
