.EndeksPaneli {
  display: flex;
  width: 100%;
  padding: 16px;
  justify-content: flex-start;
  gap: 17px;
  align-items: center;
  border-radius: 24px;
  background: var(--Dark-Mode-Card-Medium, #2b2b2e);
}
.EndeksPaneli__button {
  display: flex;
  width: 196px !important;
  height: 56px;
  padding: 8px 32px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  flex-shrink: 0;
}
.EndeksSelect {
  display: inline-flex;
  padding: 16px;
  align-items: center;
  gap: 8px;
  border-radius: 16px;
  background: var(--Dark-Mode-Card-Light, #35353a);
  opacity: 0.4;
}
.EndeksSelect.active {
  opacity: 1;
}
.EndeksSelect__icon {
}
.EndeksSelect__body {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 4px;
}
.EndeksSelect__title {
  color: var(--Dark-Mode-Text-1, #fff);
  text-align: center;

  /* Body/XLarge/Semibold */
  font-family: Mazzard;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.EndeksSelect__text {
  color: var(--Dark-Mode-Text-3, #797979);
  text-align: center;

  /* Body/Normal/Regular */
  font-family: Mazzard;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.endeks.modalSelectDiv {
  width: 49%;
}
@media screen and (max-width: 1000px) {
  .endeks.modalSelectDiv {
    width: 100%;
  }
}
