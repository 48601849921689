.PanoUser {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 16px;
}
.PanoUser__container {
  display: flex;
  width: 100%;
  padding-top: 24px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 8px;
  border-radius: 32px;
  padding: 32px;
  background: var(
    --Dark-Mode-Gradients-Card-bgGrad,
    linear-gradient(
      180deg,
      rgba(43, 43, 46, 0.8) 0%,
      rgba(34, 36, 38, 0.32) 100%
    )
  );
}
.PanoUser h3 {
  color: var(--Dark-Mode-Text-1, #fff);

  /* Heading/Heading 3 */
  font-family: Mazzard;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.PanoUser__content {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  align-self: flex-start;
  flex-wrap: wrap;
}
.PanoUser__content span {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 300px;
  height: 80px;
  padding: 24px 16px;
  justify-content: space-between;
  align-items: center;
  border-radius: 32px;
  margin-bottom: 20px;
}
.PanoUser__content__item {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  padding: 8px !important;
}
.PanoUser__content__item__cup {
}
.PanoUser__content__item__body {
  display: flex;
  width: 308px;
  padding: 24px 16px;
  justify-content: space-between;
  align-items: center;
  border-radius: 32px;
}
.PanoUser__content__item__body.bgregular {
  color: var(--Dark-Mode-Text-1, #fff);
  background: var(--Dark-Mode-Card-Medium, #2b2b2e);
}
.PanoUser__content__item__body.bg1 {
  border: 2px solid var(--Gradients-Store-ranking, rgba(255, 255, 255, 0.43));
  background: var(
    --Gradients-Gold-1st,
    linear-gradient(180deg, #ffc700 0%, #fff500 100%)
  );
  color: #1f1f21;
}
.PanoUser__content__item__body.bg2 {
  border: 2px solid var(--Gradients-Store-ranking, rgba(255, 255, 255, 0.43));
  background: var(
    --Gradients-2nd,
    linear-gradient(180deg, #ffa017 0%, #ff7817 100%)
  );
  color: #1f1f21;
}
.PanoUser__content__item__body.bg3 {
  border: 2px solid var(--Gradients-Store-ranking, rgba(255, 255, 255, 0.43));
  background: var(
    --Gradients-3rd,
    linear-gradient(180deg, #aeaeae 0%, #ececec 100%)
  );
  color: #1f1f21;
}
.PanoUser__content__item__body__top {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 8px;
  flex: 1 0 0;
}

.PanoUser__content__item__body .title {
  /* Body/XLarge/Bold */
  font-family: Mazzard;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.PanoUser__content__item__body .whois {
  /* Body/Normal/Regular */
  font-family: Mazzard;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.PanoUser__content__item__body .whois span {
  font-weight: 600;
}

.PanoUser__content__item__body.bgregular .title {
  color: var(--Dark-Mode-Text-1, #fff);
}
.PanoUser__content__item__body.bgregular .whois,
.PanoUser__content__item__body.bgregular .puan .puan__left,
.PanoUser__content__item__body.bgregular .puan .puan__right {
  color: #9a9a9a;
}

.PanoUser__content__item__body__bottom {
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
}
.PanoUser__content__item__body__bottom__text {
  /* Body/Small/Regular */
  font-family: Mazzard;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.PanoUser__content__item__body.bgregular
  .PanoUser__content__item__body__bottom__text {
  color: var(--Dark-Mode-Text-1, #fff);
}
.PanoUser__bottom {
  display: flex;
  padding: 16px 0px;
  justify-content: center;
  align-items: flex-start;
  gap: 4px;
  align-self: stretch;
}
.PanoUser__content__item__body__right {
  color: var(--Neutral-Gray-10, #1a1c1e);

  /* Body/Normal/Regular */
  font-family: Mazzard;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.PanoUser__content__item__body.bgregular .PanoUser__content__item__body__right {
  color: var(--Dark-Mode-Text-1, #fff);
}
.userTitle {
  color: var(--Dark-Mode-Text-3, #797979) !important;

  /* Body/XLarge/Semibold */
  font-family: Mazzard;
  font-size: 18px !important;
  font-style: normal;
  font-weight: 600 !important;
  line-height: normal;
}
.UserProfile {
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 100%;
}
.UserProfile__top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
  flex: 1 0 0;
}
.UserProfile__top__left {
  display: flex;
  padding: 16px 24px 16px 16px;
  align-items: center;
  gap: 32px;
  border-radius: 24px;
  border: 1px solid rgba(255, 193, 101, 0);
  background: var(
    --Dark-Mode-Gradients-Card-bgGrad,
    linear-gradient(
      180deg,
      rgba(43, 43, 46, 0.8) 0%,
      rgba(34, 36, 38, 0.32) 100%
    )
  );
}
.UserProfile__top__title svg {
  position: absolute;
  top: -16px;
  left: -16px;
}
.UserProfile__top__title {
  position: relative;
  display: flex;
  padding: 16px 24px 16px 16px;
  align-items: center;
  gap: 32px;
  border-radius: 24px;
  border: 1px solid rgba(255, 193, 101, 0);
  background: var(
    --Dark-Mode-Gradients-Card-bgGrad,
    linear-gradient(
      180deg,
      rgba(43, 43, 46, 0.8) 0%,
      rgba(34, 36, 38, 0.32) 100%
    )
  );

  border-image: linear-gradient(
    90deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 1) 50%,
    rgba(255, 255, 255, 0) 100%
  );
  border-image-slice: 1;
}
.UserProfile__top__right {
}
.userFrame {
}
.UserProfile__puans {
  display: flex;
  width: 100%;
  padding: 16px;
  justify-content: space-evenly;
  align-content: flex-start;
  gap: 16px;
  flex-wrap: wrap;
  border-radius: 40px;
  border: 4px solid #ffffff40;
}
.UserProfile__OverviewCard {
  display: flex;
  width: 31.33%;
  height: 146px;
  padding: 16px;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  flex-shrink: 0;
}
.bgOverCard1 {
  border-radius: 32px;
  border: 4px solid rgba(20, 189, 147, 0.41);
  background: var(
    --Dark-Mode-Gradients-Memnun-Grad,
    linear-gradient(180deg, #32e5b8 0%, #15ba91 100%)
  );
  box-shadow: 0px 0px 0px 8px rgba(255, 255, 255, 0.3) inset;
}
.bgOverCard2 {
  border-radius: 32px;
  border: 4px solid #9a7bff;
  background: var(
    --Dark-Mode-Gradients-Sirius-Grad,
    linear-gradient(180deg, #baa5ff 0%, #997bfd 100%)
  );
  box-shadow: 0px 0px 0px 8px rgba(255, 255, 255, 0.3) inset;
}
.bgOverCard3 {
  border-radius: 32px;
  border: 4px solid #f84141;
  background: var(
    --Dark-Mode-Gradients-Takipci-Grad,
    linear-gradient(180deg, #ff7575 0%, #f84141 100%)
  );
  box-shadow: 0px 0px 0px 8px rgba(255, 255, 255, 0.3) inset;
}
.bgOverCard4 {
  border-radius: 32px;
  border: 4px solid #00c2ff;
  background: var(
    --Dark-Mode-Gradients-Profil-Grad,
    linear-gradient(180deg, #56d7ff 0%, #00c2ff 100%)
  );
  box-shadow: 0px 0px 0px 8px rgba(255, 255, 255, 0.3) inset;
}
.bgOverCard5 {
  border-radius: 32px;
  border: 4px solid #ffae36;
  background: var(
    --Dark-Mode-Gradients-Extra-1,
    linear-gradient(180deg, #ffcb7e 0%, #ffae36 100%)
  );
  box-shadow: 0px 0px 0px 8px rgba(255, 255, 255, 0.3) inset;
}
.bgOverCard6 {
  border-radius: 32px;
  border: 4px solid #3ae4cf;
  background: var(
    --Dark-Mode-Gradients-Extra-2,
    linear-gradient(180deg, #3ae4cf 0%, #1dc4af 100%)
  );
  box-shadow: 0px 0px 0px 8px rgba(255, 255, 255, 0.3) inset;
}
.UserProfile__OverviewCard__Top {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
  color: var(--Dark-Mode-Text-1, #fff);

  /* Body/XXLarge/Semibold */
  font-family: Mazzard;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.UserProfile__OverviewCard__bottom {
  color: var(--Dark-Mode-Text-1, #fff);

  /* Heading/Heading 3 */
  font-family: Mazzard;
  font-size: 26px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.UserProfile__bottom {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
}
.UserProfile__bottom__text {
  color: var(--Dark-Mode-Text-1, #fff);

  /* Body/XLarge/Semibold */
  font-family: Mazzard;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.UserProfile__bottom__div {
  display: flex;
  width: 100%;
  padding: 16px 40px;
  justify-content: space-between;
  align-items: center;
  flex: 1 0 0;
  border-radius: 46px;
  background: var(--Dark-Mode-Card-Light, #35353a);
}
.UserProfile__bottom__div__left {
}
.UserProfile__bottom__div__title {
  color: var(--Dark-Mode-Text-1, #fff);

  /* Body/XLarge/Semibold */
  font-family: Mazzard;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.UserProfile__bottom__div__puan {
  color: var(--Dark-Mode-Text-2, #9a9a9a);

  /* Body/Small/Semibold */
  font-family: Mazzard;
  font-size: 12px;
  font-style: normal;
  line-height: normal;
  display: flex;
  align-items: center;
  gap: 16px;
}
.UserProfile__bottom__div__right {
  display: flex;
  align-items: center;
  gap: 16px;
}
.PanoUser__content__item__body__text {
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
}
@media screen and (max-width: 1000px) {
  .UserProfile__puans {
    padding: 12px;
    gap: 8px;
    border-radius: 24px;
  }
  .UserProfile__OverviewCard {
    width: 48%;
  }
  .modalWithNoPadding {
    margin: 0px !important;
    padding: 0px !important;
  }
  .modalWithNoPadding .modal-content {
    padding: 5px !important;
  }
  .modalWithNoPadding .modal-content .modal-body {
    padding: 0px !important;
  }

  .PanoUser h3 {
    font-size: 24px;
  }
}
