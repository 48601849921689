.GrafikPaneli__top {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
}
.GrafikPaneli__top h1 {
  color: var(--Dark-Mode-Text-1, #fff);

  /* Heading/Heading 3 */
  font-family: Mazzard;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;

  margin: 0;
  padding: 0;
}
.GrafikPaneli__top button {
  display: flex;
  width: 196px !important;
  height: 56px;
  padding: 8px 32px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  flex-shrink: 0;
}
.grafik_paneli_text p {
  color: var(--Dark-Mode-Text-2, #9a9a9a);

  /* Body/Large/Regular */
  font-family: Mazzard;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.grafik_paneli_text p a {
  color: var(--Dark-Mode-Text-2, #9a9a9a);

  /* Body/Large/Regular */
  font-family: Mazzard;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-decoration-line: underline;
}
@media screen and (max-width: 1000px) {
  .GrafikPaneli__top h1 {
    font-size: 24px;
  }
}
