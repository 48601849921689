.DashBoard_container {
}
.OzelArama {
  display: flex;
  width: 100%;
  padding: 16px;
  justify-content: space-between;
  gap: 17px;
  align-items: center;
  border-radius: 24px;
  background: var(--Dark-Mode-Card-Medium, #2b2b2e);
}
.OzelArama__button {
  display: flex;
  width: 196px !important;
  height: 56px;
  padding: 8px 32px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  flex-shrink: 0;
}
.OzelArama__header__tabs {
  display: inline-flex;
  flex-direction: row;
  padding: 4px;
  justify-content: center;
  align-items: center;
  width: 100%;
}
.OzelArama__container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 16px;
}
.OzelArama__header {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  padding: 8px 32px;
  padding-top: 20px;
  margin-top: 20px;
  border-radius: 24px;
  background: var(--Dark-Mode-Card-Medium, #2b2b2e);
}
.OzelArama__header h2 {
  color: var(--Dark-Mode-Text-1, #fff);

  /* Body/XLarge/Semibold */
  font-family: Mazzard;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.OzelArama__tab {
  display: flex;
  padding: 4px;
  justify-content: center;
  align-items: center;
  border-radius: 99px;
  background: var(--Dark-Mode-Card-Dark, #1f1f21);
}
.OzelArama__tab_item {
  color: var(--Dark-Mode-Text-4, #565659);

  /* Body/Large/Medium */
  font-family: Mazzard;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  display: flex;
  padding: 6px 16px;
  flex-direction: column;
  align-items: center;
}
.OzelArama__tab_item.active,
.OzelArama__tab_item:hover {
  display: flex;
  padding: 8px 16px;
  flex-direction: column;
  align-items: center;
  border-radius: 48px;
  background: var(--Dark-Mode-Card-Light, #35353a);
  color: var(--Dark-Mode-Text-1, #fff);

  /* Body/Large/Bold */
  font-family: Mazzard;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.OzelArama_lists_ {
  width: 100% !important;
}
.OzelArama_lists {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
}
.OzelArama_list_item_left {
  display: flex;
  align-items: center;
  gap: 16px;
}
.OzelArama_list_item {
  display: flex;
  width: 100%;
  height: 106px;
  padding: 16px 24px;
  justify-content: space-between;
  align-items: center;
  border-radius: 24px;
  background: var(--Dark-Mode-Card-Medium, #2b2b2e);
}
.OzelArama_list_item_title {
  display: flex;
  width: 264px;
  padding: 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 16px;
  border: 1px solid var(--Dark-Mode-Element-Divider, rgba(255, 255, 255, 0));
  background: var(--Dark-Mode-Card-Light, #444447);
}
.OzelArama_list_item_title span {
  color: var(--Dark-Mode-Text-1, #fff);

  /* Body/XLarge/Semibold */
  font-family: Mazzard;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.OzelArama_list_item_desc {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
}
.OzelArama_list_item_desc_top {
  color: var(--Dark-Mode-Text-2, #9a9a9a);

  /* Body/Normal/Regular */
  font-family: Mazzard;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
}
.OzelArama_list_item_desc_top span {
  font-weight: 600;
}
.OzelArama_list_item_desc_bottom {
  color: var(--Dark-Mode-Text-2, #9a9a9a);

  /* Body/Normal/Semibold */
  font-family: Mazzard;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  gap: 6px;
  display: flex;
  align-items: center;
  height: 24px;
}
.OzelArama_list_item_formation {
  display: flex;
  align-items: center;
  gap: 16px;
}
.OzelArama_list_item_formation_item {
  display: flex;
  padding: 8px 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 16px;
  background: var(--Dark-Mode-Card-Light, #35353a);
}
.OzelArama_list_item_formation_item span.title {
  color: var(--Dark-Mode-Text-1, #fff);

  /* Body/Normal/Regular */
  font-family: Mazzard;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.OzelArama_list_item_formation_item span.OzelArama_periods,
.AnaKumanda__filtre__bottom__left__periods {
  display: flex;
  gap: 2px;
  flex-direction: row;
}
.OzelArama_list_item_formation_item span.period {
  display: flex;
  padding: 4px 6px;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  background: var(--Brand-Main-Color, #3ab22f);
  color: var(--Dark-Mode-Text-1, #fff);
  text-align: center;

  /* Body/Normal/Regular */
  font-family: Mazzard;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 14px;
}
.OzelArama__list_item_share {
  display: flex;
  align-items: center;
  gap: 8px;
}
.OzelArama__list_item_share_item {
  display: flex;
  padding: 12px;
  max-width: 135px;
  align-items: center;
  gap: 8px;
  border-radius: 24px;
  border: 1px solid var(--Brand-Main-Color, #3ab22f);
  color: var(--Brand-Main-Color, #3ab22f);
}
.OzelArama__list_item_share_item span {
  color: var(--Brand-Main-Color, #3ab22f);

  /* Body/Normal/Medium */
  font-family: Mazzard;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.OzelArama__list_item_share_item_aktar {
  display: flex;
  padding: 12px;
  max-width: 135px;
  align-items: center;
  gap: 8px;
  border-radius: 24px;
  border: 1px solid var(--Brand-Main-Color, #dd7106);
  color: var(--Brand-Main-Color, #dd7106);
}
.OzelArama__list_item_share_item_aktar span {
  color: var(--Brand-Main-Color, #dd7106);

  /* Body/Normal/Medium */
  font-family: Mazzard;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.OzelArama__list_item_share_alarm {
  color: var(--Dark-Mode-Text-1, #fff);

  /* Body/Normal/Medium */
  font-family: Mazzard;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  display: flex;
  padding: 12px;
  align-items: center;
  gap: 8px;
}
.alarmEkli {
  color: #3ab22f;
}
.paylasildi,
.paylasildi span {
  border-radius: 24px;
  background: var(--Brand-Main-Color, #3ab22f);
  color: var(--Dark-Mode-Text-1, #fff);
}
.pasif {
  border: 1px solid var(--Brand-Main-Color, #797979);
}
.pasif,
.pasif span {
  border-radius: 24px;
  color: var(--Dark-Mode-Text-1, #797979);
}
.AramaSelect {
  display: inline-flex;
  padding: 16px;
  align-items: center;
  gap: 8px;
  border-radius: 16px;
  background: var(--Dark-Mode-Card-Light, #35353a);
  opacity: 0.4;
}
.AramaSelect.active {
  opacity: 1;
}
.AramaSelect__icon {
}
.AramaSelect__body {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 4px;
}
.AramaSelect__title {
  color: var(--Dark-Mode-Text-1, #fff);
  text-align: center;

  /* Body/XLarge/Semibold */
  font-family: Mazzard;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.AramaSelect__text {
  color: var(--Dark-Mode-Text-3, #797979);
  text-align: center;

  /* Body/Normal/Regular */
  font-family: Mazzard;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.AramaFiltre {
  display: flex;
  align-items: flex-start;
  gap: 16px;
  flex-direction: column;
  width: 100%;
}
.AramaFiltre__title {
  width: 100%;
}
.AramaFiltre__title h2 {
  color: var(--Dark-Mode-Text-1, #fff);
  text-align: left;

  /* Body/XLarge/Bold */
  font-family: Mazzard;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.AramaFiltre__body {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 4px;
  width: 100%;
  flex-wrap: wrap;
}
.AramaFiltre_item {
  display: flex;
  width: 250px;
  height: 56px;
  padding: 16px;
  align-items: center;
  justify-content: space-between;
  gap: 24px;
  border-radius: 16px;
  background: var(--Dark-Mode-Card-Light, #35353a);
}
.AramaFiltre_item span {
  color: var(--Brand-Main-Color, #9a9a9a);

  /* Body/Normal/Medium */
  font-family: Mazzard;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.AramaFiltre_item span.title {
  color: var(--Dark-Mode-Text-1, #9a9a9a);

  /* Body/Normal/Regular */
  font-family: Mazzard;
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.AramaFiltre_item span.OzelArama_periods {
  display: flex;
  gap: 4px;
  flex-direction: row;
}
.AramaFiltre_item span.period {
  display: flex;
  padding: 4px;
  align-items: center;
  gap: 2px;
  border-radius: 8px;
  background: var(--Brand-Main-Color, #3ab22f);
  color: var(--Dark-Mode-Text-1, #fff);
  text-align: center;

  /* Body/Normal/Regular */
  font-family: Mazzard;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.OzelArama__row {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
}
.OzelArama__back {
  color: var(--Dark-Mode-Text-1, #fff);

  /* Heading/Heading 5 */
  font-family: Mazzard;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  padding: 20px 0;
}
.modalLikeFormul {
  text-align: center;
}
.modalLikeFormul h2 {
  color: var(--Dark-Mode-Text-1, #fff);
  text-align: center;

  /* Heading/Heading 3 */
  font-family: Mazzard;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-top: 16px;
}
.modalLikeFormul h4 {
  color: var(--Dark-Mode-Text-1, #fff);
  text-align: center;

  /* Heading/Heading 5 */
  font-family: Mazzard;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.modalLikeFormul p {
  color: var(--Dark-Mode-Text-2, #9a9a9a);
  text-align: center;

  /* Body/Large/Medium */
  font-family: Mazzard;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.modalLikeFormul .modalLikeFormul__buttons {
  color: var(--Dark-Mode-Text-1, #fff);

  /* Body/Large/Semibold */
  font-family: Mazzard;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-top: 16px;
}
.modalLikeFormul__buttons {
  display: flex;
  justify-content: center;
}
.modalLikeFormul__buttons button {
  color: var(--Dark-Mode-Text-1, #fff);

  /* Body/Large/Semibold */
  font-family: Mazzard;
  font-size: 22px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-top: 16px;
  width: 65% !important;
}
.alarmInput {
  width: 30%;
}

.OzelArama__container {
  margin-bottom: 32px;
  padding-bottom: 32px;
}
@media screen and (max-width: 1000px) {
  .OzelArama {
    width: 100%;
    height: 100%;
    margin: 0;
  }
  .AramaSelect__title {
    font-size: 12px;
    text-wrap: nowrap;
  }
  .AramaSelect__text {
    font-size: 10px;
    text-wrap: nowrap;
  }
  .OzelArama__header {
    width: 100%;
  }
  .OzelArama__tab {
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    border-radius: 29px;
  }
  .OzelArama_list_item {
    display: flex;
    width: 100%;
    height: auto;
    padding: 12px;
    overflow-x: auto;
  }
  .OzelArama_list_item_title {
    width: auto;
    text-wrap: nowrap;
  }
  .OzelArama_list_item_title span {
    font-size: 12px;
  }
  .OzelArama_list_item_desc_top {
    font-size: 12px;
    text-wrap: nowrap;
  }
  .OzelArama_list_item_desc_bottom span {
    text-align: center;
  }
  .OzelArama_list_item_formation_item span.title {
    font-size: 12px;
    text-wrap: nowrap;
  }
  .OzelArama__container {
    margin-bottom: 32px;
  }
  .AramaFiltre__body {
    flex-wrap: wrap;
  }
  .AramaFiltre_item {
    width: auto;
  }
  .OzelArama__header__tabs {
    width: 100%;
    flex-direction: column;
  }

  .alarmInput {
    width: 100%;
  }
}
