.sozlesmeler {
  gap: 2px !important;
}
.sozlesmeler h1,
.sozlesmeler h2 {
  color: var(--Dark-Mode-Text-1, #fff);

  /* Heading/Heading 4 */
  font-family: Mazzard;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.sozlesmeler h3 {
  color: var(--Dark-Mode-Text-1, #fff);

  /* Heading/Heading 4 */
  font-family: Mazzard;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-top: 30px;
}
.sozlesmeler p {
  color: var(--Dark-Mode-Text-1, #fff);

  /* Body/XLarge/Regular */
  font-family: Mazzard;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
