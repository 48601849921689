.HomeFooter {
  background-image: url("../../../assets/img/landing_footer.png");
  background-repeat: no-repeat;
  background-size: cover;
  padding-top: 206px;
  padding-bottom: 75px;
}
.HomeFooter__container {
  background: linear-gradient(
    180deg,
    rgba(26, 28, 30, 0.65) 0%,
    rgba(26, 28, 30, 0.38) 100%
  );
  backdrop-filter: blur(26.049999237060547px);
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 16px;
  padding: 24px 0;
}
.Footer__top {
}
.Footer__logo {
  height: 50px;
}
.FooterSocial {
  height: 50px;
}
.FooterCopyrightLine {
  stroke-width: 2px;
  stroke: var(--Dark-Mode-Element-Divider, rgba(255, 255, 255, 0));
  height: 0px;
}
.FooterCopyright {
  color: var(--Dark-Mode-Text-2, #9a9a9a);

  /* Body/Large/Regular */
  font-family: Mazzard;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-align: center;
}
.Footer__social {
  display: flex;
  align-items: center;
  gap: 24px;
  float: right;
}
