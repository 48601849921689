.HomeKesfet__container {
  margin-top: 162px;
}
.HomeKesfet__title {
  color: var(--Dark-Mode-Text-1, #fff);

  /* Heading/Heading 1 */
  font-family: Mazzard;
  font-size: 48px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 76px;
  text-align: center;
}
.carousel-caption {
  position: relative;
  /* right: 15%; */
  /* bottom: 1.25rem; */
  /* left: 15%; */
  padding-top: 95px;
  /* padding-bottom: 1.25rem; */
  /* color: var(--Dark-Mode-Text-1, #fff); */
  text-align: center;
}
.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2;
  display: flex;
  justify-content: center;
  padding: 0;
  margin-right: 15%;
  margin-bottom: -30px !important;
  margin-left: 15%;
  list-style: none;
}
.carousel-indicators [data-bs-target] {
  box-sizing: content-box;
  flex: 0 1 auto;
  width: 20px;
  height: 20px;
  padding: 0;
  margin-right: 3px;
  margin-left: 3px;
  text-indent: -999px;
  cursor: pointer;
  background-color: var(--Dark-Mode-Text-1, #fff);
  background-clip: padding-box;
  border-radius: 10px;
  border-top: 0;
  border-bottom: 0;
  opacity: 0.5;
  transition: opacity 0.6s ease;
}
.carousel-indicators .active {
  opacity: 1;
}
.HomeKesfet__slider__title {
  text-align: center;
  color: white;
  font-size: 32px;
  font-family: Mazzard;
  font-weight: 700;
  overflow-wrap: break-word;
}
.HomeKesfet__slider__text {
  width: 1088px;
  height: 111px;
  text-align: center;
  color: white;
  font-size: 24px;
  font-family: Mazzard;
  font-weight: 400;
  overflow-wrap: break-word;
}
@media screen and (max-width: 1000px) {
  .HomeKesfet__container {
    margin-top: 62px;
  }
  .HomeKesfet__title {
    font-size: 36px;
  }
  .HomeKesfet__slider__title {
    font-size: 24px;
  }
  .HomeKesfet__slider__text {
    width: 100%;
    height: auto;
    font-size: 12px;
  }
}
