.accordion {
  width: 100%;
}
.accordion-item {
  margin: 24px 0;
}
.accordion-item,
.accordion-item:first-of-type .accordion-button {
  border: none !important;
  border-top-left-radius: 32px !important;
  border-top-right-radius: 32px !important;
  border-bottom-left-radius: 32px !important;
  border-bottom-right-radius: 32px !important;
  color: white !important;
}
.accordion-button {
  padding: 16px 32px !important;
  background: #2b2b2e !important;
  border: 1px solid rgba(255, 255, 255, 0.2) !important;
  box-shadow: 0px 2px 4px -2px rgba(0, 0, 0, 0.03) !important;
  border-top-left-radius: 32px !important;
  border-top-right-radius: 32px !important;
  border-bottom-left-radius: 32px !important;
  border-bottom-right-radius: 32px !important;
  color: white !important;
  height: 72px !important;
}
.accordion-button:not(.collapsed) {
  color: white !important;
  align-self: stretch !important;
  padding: 16px 32px !important;
  background: #3ab22f !important;
  box-shadow: 0px 2px 4px -2px rgba(0, 0, 0, 0.03) !important;
  border-radius: 32px !important;
  border: 1px #3ab22f solid !important;
  justify-content: space-between !important;
  align-items: center !important;
  display: inline-flex !important;
  height: 72px !important;
}
.accordion-body {
  flex: 1 1 0px;
  color: rgb(154, 154, 154);
  font-size: 16px;
  font-family: Mazzard;
  font-weight: 500;
  overflow-wrap: break-word;
}
.accordion-item:last-of-type .accordion-button.collapsed {
  height: 72px;
  color: white;
  align-self: stretch;
  padding: 16px 32px;
  background: #2b2b2e !important;
  border: 1px solid rgba(255, 255, 255, 0.2);
  box-shadow: 0px 2px 4px -2px rgba(0, 0, 0, 0.03);
  border-radius: 32px;
  justify-content: space-between;
  align-items: center;
  display: inline-flex;
}
.accordion-button::after {
  background-image: url("../../../assets/img/arrow_up.png") !important;
  width: 24px;
  height: 24px;
}
.accordion-button:not(.collapsed)::after,
.accordion-button.collapsed::after {
  background-image: url("../../../assets/img/arrow_down.png") !important;
  width: 24px;
  height: 24px;
}
.sssContainer {
  width: 100%;
  height: 100%;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 32px;
  display: inline-flex;
  margin-top: 207px !important;
}
.sssTitle {
  color: "white";
  font-size: 48px;
  font-family: Mazzard;
  font-weight: 700;
  word-wrap: "break-word";
}

@media screen and (max-width: 1000px) {
  .sssContainer {
    margin-top: 100px !important;
  }
  .sssTitle {
    font-size: 32px;
  }
}
