.ErrorPage {
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 4px;
  background-image: url("../img/error404.png");
  background-repeat: no-repeat;
  background-size: auto;
  background-position: top;
  padding: 28px 60px;
  width: 100%;
  height: 100vh;
}
.ErrorPage__text {
  margin-top: 350px;
}

.ErrorPage__text__title {
  color: var(--Dark-Mode-Text-1, #fff);
  text-align: center;

  /* Heading/Heading 3 */
  font-family: Mazzard;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.ErrorPage__text__subtitle {
  color: var(--Dark-Mode-Text-1, #fff);
  text-align: center;

  /* Heading/Heading 5 */
  font-family: Mazzard;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.ErrorPage__text__return {
  color: var(--Dark-Mode-Text-1, #fff);
  text-decoration: none;

  /* Body/Normal/Medium */
  font-family: Mazzard;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-align: center;
  width: 100%;
  display: block;
  margin-top: 30px;
}
@media screen and (max-width: 1000px) {
  .ErrorPage {
    background-size: 150%;
  }
  .ErrorPage__text {
    margin-top: 50px;
  }
}
