.forgotPage {
  background: url("../../../assets/img/login_bg.png"),
    lightgray 50% / cover no-repeat;
  height: 100vh;
  width: 100%;
}
.forgotPage__container {
  background: var(
    --Dark-Mode-Login-bgGradient,
    linear-gradient(
      180deg,
      rgba(29, 29, 32, 0.6) 0%,
      rgba(29, 29, 32, 0.65) 100%
    )
  );
  backdrop-filter: blur(10.649999618530273px);
  display: inline-flex;
  height: 100vh;
  padding: 72px;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  right: 15%;
  position: absolute;
}
.forgotPage__form_title {
  color: var(--Dark-Mode-Text-1, #fff);

  /* Heading/Heading 3 */
  font-family: Mazzard;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.forgotPage__body {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 80px;
  width: 412px;
}
.forgotPage__form {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}
.forgotPage_form_body {
  width: 100%;
}
.input-form-control {
  display: flex;
  height: 56px;
  width: 100%;
  padding: 0 16px;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  border-radius: 16px;
  border: 1px solid var(--Dark-Mode-Input-Border, #35353a);
  background: var(
    --Dark-Mode-Login-bgGradient,
    linear-gradient(
      180deg,
      rgba(29, 29, 32, 0.6) 0%,
      rgba(29, 29, 32, 0.65) 100%
    )
  );
}

.form-control,
.form-control:focus {
  border: none;
  color: var(--Dark-Mode-Text-1, #fff);
  background: none;
  font-family: Mazzard;
}
.forgotPage__forgotPassword {
  display: flex;
  height: 56px;
  padding: 8px 32px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  align-self: stretch;
}
.forgotPage__forgotPassword a {
  color: var(--Dark-Mode-Text-2, #9a9a9a);

  /* Body/Large/Semibold */
  font-family: Mazzard;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
a.forgotPage__return__homepage {
  font-size: 12px;
  font-weight: 200;
}
@media screen and (max-width: 768px) {
  .forgotPage__container {
    right: 0;
    padding: 32px;
    margin: 0 auto;
    left: 0;
  }
  .forgotPage__body {
    width: 312px;
  }
}
