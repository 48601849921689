.ProfilTop {
  display: flex;
  width: 100%;
  padding: 24px 0px;
  justify-content: space-between;
  align-items: flex-start;
}
.ProfilTop__left {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 24px;
  width: 100%;
}
.ProfilTop__left__user {
  display: flex;
  padding: 16px 24px 16px 16px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 8px;
  border-radius: 24px;
  border: 1px solid var(--Dark-Mode-Element-Divider, rgba(255, 255, 255, 0));
  background: var(
    --Dark-Mode-Gradients-Card-bgGrad,
    linear-gradient(
      180deg,
      rgba(43, 43, 46, 0.8) 0%,
      rgba(34, 36, 38, 0.32) 100%
    )
  );
}
.ProfilTop__left__user__title {
  color: #fff;

  /* Body/Normal/Medium */
  font-family: Mazzard;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.ProfilTop__left__user__name {
  display: flex;
  padding: 16px;
  align-items: center;
  gap: 64px;
  border-radius: 16px;
  background: var(--Dark-Mode-Card-Light, #35353a);
}
.ProfilTop__left__user__name__text {
  color: var(--Dark-Mode-Text-1, #fff);

  /* Body/Large/Semibold */
  font-family: Mazzard;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.ProfilTop__left__user__name__edit {
  color: var(--Dark-Mode-Text-1, #fff);

  /* Body/Small/Regular */
  font-family: Mazzard;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.ProfilTop__left__pano {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}
.ProfilTop__left__pano h4 {
  color: var(--Dark-Mode-Text-1, #fff);

  /* Heading/Heading 4 */
  font-family: Mazzard;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 0;
}
.ProfilTop__left__pano__publish {
  display: flex;
  padding: 16px 24px 16px 16px;
  align-items: center;
  gap: 32px;
  border-radius: 24px;
  border: 1px solid var(--Dark-Mode-Element-Divider, rgba(255, 255, 255, 0.08));
  background: var(--Dark-Mode-Gradients-Card-bgGrad, rgba(43, 43, 46, 0.34));
}
.ProfilTop__left__pano__publish__left {
  display: flex;
  padding: 16px;
  align-items: center;
  gap: 8px;
  color: var(--Dark-Mode-Text-1, #fff);

  /* Body/Large/Semibold */
  font-family: Mazzard;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  border-radius: 16px;
  border: 1px solid var(--dark-mode-element-dvider-100, rgba(255, 255, 255, 0));
  border-image: linear-gradient(
    90deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 1) 50%,
    rgba(255, 255, 255, 0) 100%
  );
  border-image-slice: 1;
}
.ProfilTop__left__pano__publish__center {
  display: flex;
  padding: 16px;
  align-items: center;
  gap: 8px;
  border-radius: 16px;
  border: 1px solid var(--dark-mode-element-dvider-100, rgba(255, 255, 255, 0));
  background: var(--Neutral-Gray-2, #f0f0f0);
  position: relative;
}
.ProfilTop__left__pano__publish__center img {
  position: absolute;
  left: -16px;
  top: 5px;
}
.ProfilTop__left__pano__publish__center__text {
  color: var(--Dark-Mode-Text-4, #565659);

  /* Body/Large/Semibold */
  font-family: Mazzard;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.ProfilTop__left__pano__publish__center__degree {
  font-weight: 400;
}
.ProfilTop__left__pano__publish__right {
  display: flex;
  padding: 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 16px;
  border: 1px solid var(--dark-mode-element-dvider-100, rgba(255, 255, 255, 0));
  background: var(--Colors-Mustard, #ffae36);
  position: relative;
}
.ProfilTop__left__pano__publish__right img {
  position: absolute;
  left: -14px;
  top: 9px;
}
.ProfilTop__left__pano__publish__right span {
  color: var(--Neutral-Gray-9, #1f1f21);

  /* Body/Large/Semibold */
  font-family: Mazzard;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.ProfilTop__right {
  display: flex;
  padding: 4px;
  justify-content: center;
  align-items: center;
  border-radius: 99px;
  border: 1px solid rgba(255, 255, 255, 0.04);
  background: var(--Dark-Mode-Card-Dark, #1f1f21);
}
.ProfilTop__right__left,
.ProfilTop__right__right {
  display: flex;
  width: 160px;
  padding: 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;
}
.ProfilTop__right__left.active,
.ProfilTop__right__right.active {
  border-radius: 48px;
  background: var(--Dark-Mode-Card-Light, #35353a);
}
.ProfilTop__modal {
  display: inline-flex;
  flex-direction: column;
  width: 100%;
  gap: 24px;
}
.ProfilTop__modal .form-label {
  color: var(--Dark-Mode-Input-Label, #fff);

  /* Body/Normal/Medium */
  font-family: Mazzard;
  font-size: 14px;
  margin-left: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-bottom: 0;
}
.ProfilTop__modal .input-group {
  display: flex;
  height: 56px;
  padding: 0 16px;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  border-radius: 16px;
  border: 1px solid var(--Dark-Mode-Card-Light, #35353a) !important;
  background: var(--Dark-Mode-Card-Medium, #2b2b2e) !important;
}
@media screen and (max-width: 1000px) {
  .ProfilTop {
    padding-bottom: 0 !important;
  }
  .ProfilTop__left {
    flex-direction: column;
  }
  .ProfilTop__left__user {
    width: 100% !important;
  }
  .ProfilTop__left__user__name {
    width: 100% !important;
    justify-content: space-between;
  }
  .ProfilBody__OverviewCard {
    width: 45% !important;
  }
  .ProfilBottom__container {
    flex-direction: column;
    gap: 35px;
  }
  .ProfilBottom__container__left,
  .ProfilBottom__container__right {
    width: 100% !important;
  }
  .ProfilTop__right {
    display: none;
  }
  .ProfilTop__left__pano {
    width: 100% !important;
  }
  .PanoTop__publish {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around;
  }
  .PanoTop__publish__center__text.rank {
    padding: 6px;
    border-radius: 10px;
    font-size: 12px;
  }
}
