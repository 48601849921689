.HomeNelerBekliyor {
  background-image: url("../../../assets/img/langing_space.png");
  background-repeat: no-repeat;
  background-size: contain;
  width: 100%;
  height: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 80px 0px;
}
.HomeNelerBekliyor__row {
  display: inline-flex;
  flex-direction: row;
  align-items: center;
}
.HomeNelerBekliyor__title {
  color: var(--Dark-Mode-Text-1, #fff);

  /* Heading/Heading 1 */
  font-family: Mazzard;
  font-size: 48px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-top: 295px;
  margin-bottom: 30px;
}
.HomeNelerBekliyor__item {
  padding: 12px;
}
.HomeNelerBekliyor__item_body {
  display: flex;
  height: 360px;
  padding: 24px;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  flex-shrink: 0;
  border-radius: 32px;
  border: 1px solid var(--Dark-Mode-Element-Divider, rgba(255, 255, 255, 0));
  background: linear-gradient(180deg, #2b2b2e 0%, rgba(43, 43, 46, 0.21) 100%);
}
@media screen and (max-width: 1000px) {
  .HomeNelerBekliyor__title {
    margin-top: 50px;
    font-size: 32px;
  }
}
